import React, { useEffect, useState } from "react";
import "./../agora/VoiceCall.css";
import SparessSidemenu from "../pages/include/sparess-sidemanu";
import { roomEnable, micControl, joinRoom, leaveRoom } from "./agora";
import { apiRequest } from "../config/apiRequest";
import { API_END_URLS } from "../config/endUrls";
import { useNavigate, useParams } from "react-router-dom";
import { APPID } from "../config/constants";
import Sparessheader from "../pages/include/sparess-header";
import AgoraRTC from "agora-rtc-sdk-ng";
import { showMessage } from "../pages/frontend/toast";
import { Container } from "react-bootstrap";

let rtc = {
  localAudioTrack: null,
  localVideoTrack: null,
  client: null,
};

function VoiceCall() {
  const navigate = useNavigate();

  const [isMicEnable, setIsMicEnable] = useState(true);
  const { channel_name } = useParams();

  const startVoiceCall = async () => {
    try {
      await roomEnable(false);
      let { data, success } = await apiRequest({
        endUrl: API_END_URLS.getVideoToken,
        params: { channelName: channel_name },
      });

      if (success) {
        const room = await joinRoom({
          appId: APPID,
          channel: channel_name,
          token: data.token,
          uid: data.uid,
          isVideoCall: false,
        });
        if (room.isStart) {
          showMessage("Voice call has been started", "success");
        } else {
          showMessage(data?.error, "error");
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  const onEndCall = async () => {
    const shouldEndCall = window.confirm(
      "Are you sure you want to end the call?"
    );
    if (shouldEndCall) {
      try {
        await leaveRoom();
        navigate("/seller/connect");
      } catch (error) {
        // Handle any errors that might occur during leaveRoom
        console.error("Error during leaveRoom:", error);
      }
    } else {
      // If the user cancels, do nothing or perform additional actions as needed
    }
  };

  useEffect(() => {
    roomEnable();
    startVoiceCall();
    return () => leaveRoom();
  }, []);

  return (
    <React.Fragment>
      <div class="container-fluid" id="seller-panel">
        <div class="row">
          <div class="col-md-3 d-none d-sm-block pr-0">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box p-3">
              <div className="page-headpaddingbox">
                <Sparessheader></Sparessheader>

                {/* <div className="call-newbox">

                <div className="row justify-content-center ">
                  <div className="col-md-10">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <div className="voice-userimg">
                          <img src="/images/user-green.png" alt="" />
                        </div>
                        <div
                          className="video-calluser position-relative"
                          id="rm-container"
                        ></div>
                        <p>Seller</p>
                      </div>
                      <div className="col-md-4">
                        <div className="mic-innermainbox">
                          <div className="mic-innermainbox2">
                            <div className="mic-innermainbox3">
                              <div className="mic-imggreen">
                                <img src="/images/mic-green.png" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="voice-userimg">
                          <img src="/images/client-green.png" alt="" />
                        </div>
                        <div
                          className="user-imgvideocallmainbox"
                          id="lc-container"
                        ></div>
                        <p>Customer</p>
                      </div>
                    </div>
                      <div className="contact-uservideo">
                        {isMicEnable ? (
                          <div className="mute-box mr-4">
                            <img
                              src={"/images/mic-icon.png"}
                              alt=""
                              onClick={() => {
                                setIsMicEnable(false);
                                micControl(!isMicEnable);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="mute-box mr-4">
                            <img
                              src={"/images/Mute.png"}
                              alt="/"
                              onClick={() => {
                                setIsMicEnable(true);
                                micControl(!isMicEnable);
                              }}
                            />
                          </div>
                        )}
                      
                        <div className="mute-box mr-4">
                          <img
                            src={"/images/action-icon.png"}
                            alt=""
                            onClick={() => {
                              onEndCall();
                            }}
                          />
                        </div>
                      </div>
                  </div>
                </div>
                </div> */}

                <div class="voice-pagemain">
                  <div class="row justify-content-center ">
                    <div class="col-md-10">
                      <div class="row align-items-center">
                        <div class="col-md-4">
                          <div class="voice-userimg" id="rm-container">
                            <img src="/images/user-green.png" />
                          </div>
                          <p>Seller</p>
                        </div>
                        <div class="col-md-4">
                          <div class="mic-innermainbox">
                            <div class="mic-innermainbox2">
                              <div class="mic-innermainbox3">
                                <div class="mic-imggreen">
                                  <img src="/images/mic-green.png" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="voice-userimg" id="lc-container">
                            <img src="/images/client-green.png" />
                          </div>
                          <p>Customer</p>
                        </div>
                      </div>

                      {/* <div class="text-center mt-sm-5">
                        <span class="phone-box">
                          <img src="images/local-phonegreen.png" />
                        </span>
                      </div> */}

                      <div className="d-flex justify-content-center mt-sm-5">
                        {isMicEnable ? (
                          <div className="mute-box mr-4">
                            <img
                              src={"/images/mic-icon.png"}
                              alt=""
                              onClick={() => {
                                setIsMicEnable(false);
                                micControl(!isMicEnable);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="mute-box mr-4">
                            <img
                              src={"/images/Mute.png"}
                              alt="/"
                              onClick={() => {
                                setIsMicEnable(true);
                                micControl(!isMicEnable);
                              }}
                            />
                          </div>
                        )}
                      
                        <div className="mute-box mr-4">
                          <img
                            src={"/images/action-icon.png"}
                            alt=""
                            onClick={() => {
                              onEndCall();
                            }}
                          />
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VoiceCall;
