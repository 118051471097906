import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Password from '../../../components/Forms/Password';
import { apiRequest } from '../../../config/apiRequest';
import { API_END_URLS } from '../../../config/endUrls';
import { _message } from '../../../config/messages';
import { showMessage } from '../../../helpers/toast';
import { setGlobalState } from '../../../redux/dispatch';
import { fieldValidate } from '../validator';


function ConfirmPassword() {
    const navigate = useNavigate();
    const [state,setState] = useState({pass:"",confPass:""})
    const {token} = useParams()
    const passRef = useRef()
    const confPass = useRef()
    const submit = async () => {
        try {
            if(!state.pass){
                return showMessage(_message.auth.PEYP)
            }
            else if(state.pass !== state.confPass){
                return showMessage("Password mismatch")
            }
            
            let res = fieldValidate({field:"password",value:state.pass.trim()})
            if(!res.isValid){
                return showMessage(res.msg)
            }
            const payload = {
                token,
                password  :state.pass.trim()
            }
            setGlobalState({ loader: true })
            const { success, message } =  await  apiRequest( { endUrl: API_END_URLS.restPassword, body: payload, method: "post", token:false})
            setGlobalState({ loader: false })
            if(success){
                showMessage(message,"success")
                navigate('/Login')
                
            }
        } catch(e){
        console.log("🚀 ~ file: confirm-password.js:32 ~ submit ~ e", e)
            showMessage(showMessage,"error")

        }

    }
    return (
        <>
            <section className="py-4 py-sm-5 register-bg login-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row  my-4 my-sm-5 ">
                                <div className="col-md-4">

                                    <div className="register-lefttextbox">
                                        <Link to="/"> <img src="images/left-bluearrow.png" alt='' /> </Link>
                                        <h1>Welcome To </h1>
                                        <img src="images/login-logo.png" alt='' className="login-logonew" />
                                        <p>An Online Platform to Buy & Sell <br /> Marine and Offshore Equipment, <br /> Products, Parts and Services</p>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="row justify-content-end pt-2 pt-sm-4 pt-lg-5">
                                        <div className="col-md-7">
                                            <form>
                                                <div className="register-righttextbox">
                                                    <h3>Create Password</h3>
                                                    <Password 
                                                    passwordRef={passRef} 
                                                    placeholder={"Password"} 
                                                    onChange={(text)=>setState({...state,pass:text})}
                                                    />
                                                    <Password  
                                                    passwordRef={confPass} 
                                                    placeholder={"confirm password"} 
                                                    onChange={(text)=>setState({...state,confPass:text})}
                                                    />
                                                   
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-9 col-8">
                                                            <div className="d-flex justify-content-between align-items-center pt-3">
                                                                <button type="button" className=" newlogin-btn" onClick={submit}>submit</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ConfirmPassword;