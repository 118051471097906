import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// import { Container } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import { apiRequest } from '../../../config/apiRequest';
import { API_END_URLS } from '../../../config/endUrls';
import { _message } from '../../../config/messages';
import { showMessage } from '../../../helpers/toast';
import { setGlobalState } from '../../../redux/dispatch';

function TwostepVerification() {
    const navigate = useNavigate()
    /* OTP Verify Code */
    const [code] = useState("");
    const { token, email } = useParams()
    const inputRef = useRef([])

    const handleOtpVerify = async () => {
        try {
            let otp = ""
            inputRef.current.forEach((item, index) => {
                otp = otp + inputRef.current[index].value
            })
            if (otp.length < 6) {
                return showMessage(_message.auth.ValidOTP)
            }
            const payload = {
                otp
            }
            console.log("🚀 ~ file: twostep-verification.js:14 ~ handleOtpVerify ~ handleOtpVerify", code)
            setGlobalState({ loader: true })
            const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.postVerifyOtp, body: payload, method: "post", token: true, customToken: token })
            setGlobalState({ loader: false })
            if (success) {
                showMessage(message, "success")
                navigate('/security-question/1/' + token)
            } else {
                showMessage(message, "message")
            }
        } catch (e) {
            console.log("🚀 ~ file: twostep-verification.js:27 ~ handleOtpVerify ~ e", e)
            showMessage(showMessage, "error")
        }
    }


    /* OTP Length validation  */
    const changeOtp = (e, i) => {
        var target = e.srcElement || e.target;
        var myLength = target.value.length;
        if (myLength >= 1) {
            if (i <= 4)
                inputRef.current[i + 1].focus()
        }
        else if (myLength === 0) {
            if (i >= 1)
                inputRef.current[i - 1].focus()
        }
    }

    return (
        <>
            <section class="signup-bg question-bg">
                <div class="container">
                    <div class="row py-4 py-sm-5">
                        <div class="col-md-5 col-11 mb-4 mb-sm-0">
                            <div class="signup-lefttextbox">
                                <h1>Welcome To</h1>
                                <img src="/images/signup-logo.png" className="signup-logo" alt='/' />
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div className="row pt-sm-4 ">
                                <div className="col-md-10">
                                    <div class="signup-righttextbox">
                                        <div class="row mt-sm-5 mt-3 justify-content-end">
                                            <div class="col-md-8">
                                                <h3>Two Step Verification</h3>
                                                <p>We sent a verification code to your email.</p>
                                                <p className="mb-sm-5 mb-4">{email}</p>

                                                <div className="row mt-4 mt-sm-5 pb-2">
                                                    {
                                                        [1, 2, 3, 4, 5, 6].map((item, i) =>
                                                            <div className="col-md-2 col-lg-2 col-2">
                                                                <input maxLength={1} ref={(e) => inputRef.current[i] = e} type="text" className="verification-input" onChange={(e) => changeOtp(e, i)} />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                
                                                <div className="mt-sm-5 mt-4">
                                                    <button type="button" class="next-btn btn-block" onClick={handleOtpVerify}>Verify</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TwostepVerification;