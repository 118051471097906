import React from 'react'
import { LinkedinIcon, LinkedinShareButton } from 'react-share'

export default function LinkedIn() {
    return (
        <>
            <LinkedinShareButton
                url={'https://www.linkedin.com/'}
            >
                <LinkedinIcon className='mr-2' size={32} round />
            </LinkedinShareButton>

            
        </>
    )
}
