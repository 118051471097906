import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
// import Home from './pages/frontend/index';

/* All  Authentication Import */
import Register from "./pages/frontend/auth/register";
import Login from "./pages/frontend/auth/login";
import Question from "./pages/frontend/question";
import SallerSecurityQuestion from "./pages/seller/securityQuestions/questionOne";
import SallerSecurityQuestionTwo from "./pages/seller/securityQuestions/questionTwo";
import SallerSecurityQuestionThree from "./pages/seller/securityQuestions/questionThree";
import ForgetPassword from "./pages/frontend/auth/forget-password";
import ForgetPasswordOTP from "./pages/frontend/auth/forget-password-otp";
import TwostepVerification from "./pages/frontend/auth/twostep-verification";
import ConfirmPassword from "./pages/frontend/auth/confirm-password";

import HomeNew from "./pages/frontend/index-new";
import AboutUs from "./pages/frontend/AboutUs";
import Blogs from "./pages/frontend/blogs";

import Wishlist from "./pages/frontend/Wishlist";
import DealsPromotions from "./pages/frontend/deals";
import AdvertisementForm from "./pages/frontend/Advertisement/advertisement-form";
import Review from "./pages/frontend/Review";

// import Home from "./pages/frontend";
import ContactUs from "./pages/frontend/contact-us";
import Planchoose from "./pages/frontend/plan-choose";
import RangeProduct from "./pages/frontend/range-product";
import { ToastContainer } from "react-toastify";
import React, { useEffect, useState } from "react";
import Global from "./components/Global";
import { store } from "./redux/combine";
import { Provider } from "react-redux";
import Posting from "./pages/seller/Posting";
// import SellerPosting from "./pages/seller/posting";
import SellerDashboard from "./pages/seller/sparess-dashboard";
import SellerProfile from "./pages/seller/profile/sparess-profile";
import SellerProfileEdit from "./pages/seller/profile/sparess-profileEdit";
import SellerPaidservices from "./pages/seller/paid-services";
import SellerProductEdit from "./pages/seller/posting/product/SellerProductEdit";
import SellerProductCreate from "./pages/seller/posting/product/SellerProductCreate";
// import SellerProductListing from "./pages/seller/posting/product/SellerProductListing";
import SellerServiceListing from "./pages/seller/posting/services/SellerServiceListing";
import SellerServiceCreate from "./pages/seller/posting/services/SellerServiceCreate";
import SellerServiceEdit from "./pages/seller/posting/services/SellerServiceEdit";

import LoginRoute from "./routes/LoginRoute";
import PrivateRoute from "./routes/PrivateRoute";
import AllProductListing from "./pages/frontend/products/AllProductListing";

import CategoryProductListing from "./pages/frontend/products/CategoryProductListing";

import ProductView from "./pages/frontend/products/ProductView";
import ScrollToTop from "./components/ScrollToTop";
import SettingServices from "./pages/seller/setting/Services";
import HelpDesk from "./pages/seller/setting/HelpDesk";
import ChangePassword from "./pages/seller/setting/PasswordChange";
import ChooseBasicPlan from "./pages/frontend/ChooseBasicPlan";
// import { InitPayment } from "./stripe/Checkout";
// import Redirect from "./stripe/Redirect";
import TermsCondition from "./pages/frontend/TermsCondition";
import PrivacyPolicy from "./pages/frontend/PrivacyPolicy";
import ReadMore from "./pages/ReadMore";
// import VideoCall from "./agora/index";
import Connect from "./pages/connect/UserConnect";
import VideoCall from "./agora/Video";
import AdvertisementPreview from "../src/pages/frontend/Advertisement/PreviewAdverstisement";
import AvertisementList from "./pages/frontend/Advertisement/AvertisementList";
import Advertisement from "./pages/frontend/Advertisement/AdvertisementRedirect";

/* New Code Start */
import HomePage from "./pages/frontend/Dashboard/index";
import WhatWeOffer from "./pages/WhatWeOffer";
import Promotion from "./pages/seller/promotion";

import Excisting from "./pages/seller/excisting";
import PaymentRedirectSuccess from "./TelrPaymentGatway/PaymentRedirectSuccess";
import PaymentRedirectDecline from "./TelrPaymentGatway/PaymentRedirectDecline";
import PaymentRedirectCancle from "./TelrPaymentGatway/PyamentRedirectCancle";
import UserInfoChat from "./pages/connect/UserInfoChat";
import CookiePolicy from "./pages/frontend/CookiePolicy";
import AdvertisementPaymenDecline from "./pages/frontend/Advertisement/AdvertisementPaymenDecline";
import AdvertisementPaymentPending from "./pages/frontend/Advertisement/AdvertisementPaymentPending";
import AdvertisementInfo from "./pages/frontend/Advertisement/AdvertisementInfo";
import VoiceCall from "./agora/VoiceCall";
import TagManager from "react-gtm-module";
import DealsProducts from "./pages/frontend/products/DealsAndPromotion";


function App() {
  const tagManagerArgs = {
    gtmId: "G-PY0PNDJB8D",
  };

  TagManager.initialize(tagManagerArgs);
  return (
    <React.Fragment>
      <Provider store={store}>
        <Global />
        <Router>
          <Routes>
            <Route
              path="/payment/return-auth"
              element={<PaymentRedirectSuccess />}
            />
            <Route
              path="/payment/return-decline"
              element={<PaymentRedirectDecline />}
            />
            <Route
              path="/payment/return-cancle"
              element={<PaymentRedirectCancle />}
            />

            <Route path="/" element={<HomePage />} />
            <Route path="/Register" exact element={<Register />} />
            <Route
              path="/Login"
              exact
              element={
                <LoginRoute>
                  {" "}
                  <Login />
                </LoginRoute>
              }
            />
            <Route path="/Question" exact element={<Question />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            <Route
              path="/forget-password-otp/:token"
              element={<ForgetPasswordOTP />}
            />
            <Route
              path="/twostep-verification/:token/:email"
              element={<TwostepVerification />}
            />
            <Route
              path="/security-question/1/:token"
              element={<SallerSecurityQuestion />}
            />
            <Route
              path="/security-question/2/:token"
              element={<SallerSecurityQuestionTwo />}
            />
            <Route
              path="/security-question/3/:token"
              element={<SallerSecurityQuestionThree />}
            />
            <Route
              path="/resent-password/:token"
              element={<ConfirmPassword></ConfirmPassword>}
            ></Route>

            <Route path="/about-us" exact element={<AboutUs />} />
            {/* <Route path="/blogs" exact element={<Blogs />} /> */}
            <Route path="/blog/get-one-blog/:id?" exact element={<Blogs />} />
            <Route path="/seller/readmore/:id" exact element={<ReadMore />} />

            <Route path="/wish-list" exact element={<Wishlist />} />
            <Route
              path="/DealsPromotions"
              exact
              element={<DealsPromotions />}
            />

            <Route path="/review" exact element={<Review />} />
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/choose-plan" exact element={<Planchoose />} />

            <Route
              path="/range-products/:id"
              exact
              element={<RangeProduct />}
            />
            <Route path="/our-services" exact element={<WhatWeOffer />} />

            <Route
              path="/terms-conditions"
              exact
              element={<TermsCondition />}
            />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />

            {/* frontend */}
            <Route path="/product/view/:id" exact element={<ProductView />} />
            {/* <Route path="/products/:catid" exact element={<ProductListing />} /> */}
            <Route
              path="/all-products/:id"
              exact
              element={<AllProductListing />}
            />
            <Route
              path="/products-list/:id"
              exact
              element={<CategoryProductListing />}
            />

            <Route
              path="/dealsAndPromotion-products/:id"
              exact
              element={<DealsProducts />}
            />

            {/* seller dashboard code start */}
            <Route
              path="/seller/dashboard"
              exact
              element={
                <PrivateRoute>
                  <SellerDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/posting/add-product"
              exact
              element={
                <PrivateRoute>
                  <SellerProductCreate />
                </PrivateRoute>
              }
            />

            {/* Draft and Save Product Start Code */}

            <Route
              path="/seller/posting/add-product/:id/draft"
              exact
              element={
                <PrivateRoute>
                  <SellerProductCreate />
                </PrivateRoute>
              }
            />
            {/* Draft and Save Product End Code */}

            <Route
              path="/seller/posting/add-product/:id"
              exact
              element={
                <PrivateRoute>
                  <SellerProductCreate />
                </PrivateRoute>
              }
            />

            <Route
              path="/seller/profile"
              exact
              element={
                <PrivateRoute>
                  <SellerProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/profile-edit"
              exact
              element={
                <PrivateRoute>
                  <SellerProfileEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/paid-services"
              exact
              element={
                <PrivateRoute>
                  <SellerPaidservices />
                </PrivateRoute>
              }
            />
            {/* seller dashboard code start */}
            <Route
              path="/seller/dashboard"
              exact
              element={
                <PrivateRoute>
                  <SellerDashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/seller/posting"
              exact
              element={<PrivateRoute></PrivateRoute>}
            />
            <Route
              path="/seller/profile"
              exact
              element={
                <PrivateRoute>
                  <SellerProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/profile-edit"
              exact
              element={
                <PrivateRoute>
                  <SellerProfileEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/paid-services"
              exact
              element={
                <PrivateRoute>
                  <SellerPaidservices />
                </PrivateRoute>
              }
            />

            <Route
              path="/seller/posting/services"
              exact
              element={
                <PrivateRoute>
                  <SellerServiceListing />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/posting/service/add"
              exact
              element={
                <PrivateRoute>
                  <SellerServiceCreate />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/posting/service/:id"
              exact
              element={
                <PrivateRoute>
                  <SellerServiceEdit />
                </PrivateRoute>
              }
            />

            <Route
              path="/seller/setting/services"
              exact
              element={
                <PrivateRoute>
                  <SettingServices />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/setting/help-desk"
              exact
              element={
                <PrivateRoute>
                  <HelpDesk />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/setting/change-password"
              exact
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/subscription-choose-plans/:type"
              exact
              element={
                <PrivateRoute>
                  <ChooseBasicPlan />
                </PrivateRoute>
              }
            />
            <Route
              path="/seller/connect/:id?"
              exact
              element={
                <PrivateRoute>
                  <Connect />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/seller/video/:channel_name"
              exact
              element={
                <PrivateRoute>
                  <VideoCall></VideoCall>
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/seller/voice-call/:channel_name"
              exact
              element={
                <PrivateRoute>
                  <VoiceCall></VoiceCall>
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/Advertisement-rule"
              exact
              element={<AdvertisementInfo />}
            />
            <Route
              path="/AdvertisementForm"
              exact
              element={<AdvertisementForm />}
            />
            <Route
              path="/seller/advertisement-preview"
              exact
              element={
                <PrivateRoute>
                  <AdvertisementPreview></AdvertisementPreview>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/seller/advertisement-list"
              exact
              element={
                <PrivateRoute>
                  <AvertisementList></AvertisementList>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/advertisement/payment/return-auth"
              element={<Advertisement />}
            />

            <Route
              path="/advertisement/payment/return-cancel"
              element={<AdvertisementPaymentPending />}
            />
            <Route
              path="/advertisement/payment/return-decline"
              element={<AdvertisementPaymenDecline />}
            />

            <Route
              path="/AdvertisementForm"
              exact
              element={<AdvertisementForm />}
            />
            <Route
              path="/seller/advertisement-preview"
              exact
              element={
                <PrivateRoute>
                  <AdvertisementPreview></AdvertisementPreview>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/seller/advertisement-list"
              exact
              element={
                <PrivateRoute>
                  <AvertisementList></AvertisementList>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/stripe-advertisemet-payment-redirect"
              element={<Advertisement />}
            />
            <Route
              path="/seller/addservice"
              exact
              element={
                <PrivateRoute>
                  <SellerServiceCreate />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/seller/addservice/:id/draft"
              exact
              element={
                <PrivateRoute>
                  <SellerServiceCreate />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/seller/promotion"
              exact
              element={
                <PrivateRoute>
                  <Promotion></Promotion>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/seller/posting/products"
              exact
              element={
                <PrivateRoute>
                  <Posting></Posting>
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/seller/excisting"
              exact
              element={
                <PrivateRoute>
                  <Excisting></Excisting>
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/seller/user-info-chat"
              exact
              element={
                <PrivateRoute>
                  <UserInfoChat></UserInfoChat>
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/seller/cookie-policy"
              exact
              element={<CookiePolicy></CookiePolicy>}
            ></Route>

            {/* seller dashboard code end */}
          </Routes>
          <ScrollToTop />
        </Router>
      </Provider>
    </React.Fragment>
  );
}

export default App;
