import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Navigate, useNavigate } from "react-router-dom";
import { showMessage } from "../../../helpers/toast";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import PreviewAdverstisement from "./PreviewAdverstisement";
import { getCredentials } from "../../../helpers/auth";
import { useFormik } from "formik";
import AddAdvertisement from "./AddAdvertisement";
import { setGlobalState } from "../../../redux/dispatch";

function AdvertisementForm() {
  const [activeTab, setActiveTab] = useState("home");

  const navigate = useNavigate();

  const credentials = getCredentials();

  const initialValues = {
    media: undefined,
    media_type: "",
    advertisement_type: null,
    video_duration: "0",
    media_url: "",
    media_duration: undefined,
    media_preview: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        let req = {
          media_type: values.media_type,
          advertisement_type:  values.advertisement_type, 
          video_duration: values.video_duration,
          media_url: values.media_url,
        };

        const fileData = new FormData();
        let fileJson = {};
        fileData.append("file", values.media);
        fileJson[values.media.name] = "Advertisement/" + values.media.name;
        fileData.append("jsondata", JSON.stringify(fileJson));
        const uploadResponse = await apiRequest({
          endUrl: API_END_URLS.fileUpload,
          method: "post",
          body: fileData,
          headerType: "file",
        });
        if (uploadResponse.success) {
          req["media_url"] = uploadResponse.data;

          const { data, success, message } = await apiRequest({
            endUrl: API_END_URLS.addAdvertisement,
            method: "POST",
            body: req,
          });

          if (success) {
            window.location.replace(data?.payment?.order?.url);
            // navigate("/checkout?secret=" + data?.clientSecret + "&adv_id=" + data?.orderGenerated?.advertisementId)
            // showMessage("Advertisement created successfully")
          } else {
            showMessage(message.error);
          }
        }

        // setGlobalState({ loader: true });
        // const { data, success, message } = await apiRequest({
        //   endUrl: API_END_URLS.addAdvertisement,
        //   method: "POST",
        //   body: req,
        // });

        // if (success) {
        //   window.location.replace(data?.payment?.order?.url);
        //   const fileData = new FormData();
        //   let fileJson = {};
        //   fileData.append("file", values.media);
        //   fileJson[values.media.name] = "Advertisement/" + values.media.name;
        //   fileData.append("jsondata", JSON.stringify(fileJson));
        //   const uploadResponse = await apiRequest({
        //     endUrl: API_END_URLS.fileUpload,
        //     method: "post",
        //     body: fileData,
        //     headerType: "file",
        //   });
        //   if (uploadResponse.success) {
        //     req["media_url"] = uploadResponse.data;
        //   }
        // setGlobalState({ loader: false });
        // } else {
        //   showMessage(message);
        // }
      } catch (error) {
        console.log(
          "🚀 ~ file: advertisement-form.js:37 ~ onSubmit: ~ error:",
          error
        );
      }
    },
  });

  const { values, handleSubmit } = formik;
  console.log("🚀 ~ AdvertisementForm ~ values:", values)

  if (!credentials.is_subscribed) {
    showMessage("Please Login");
    return <Navigate to="/Login" />;
  }

  return (
    <React.Fragment>
      <section className="pb-4 pb-sm-5 pt-4 buy-section ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-8">
                  <div className="buy-formtext">
                    <h1
                      onClick={() => navigate("/")}
                      style={{ cursor: "pointer" }}
                    >
                      Buy your Advertisement space Here
                    </h1>
                    <div className="heading-seperator heading-seperator2">
                      <span></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-left text-sm-right">
                  <img
                    src="images/orange-logo.png"
                    className="buy-logo"
                    alt=""
                    onClick={() => navigate("/")}
                  />
                </div>
              </div>
              <div className="row justify-content-center mt-3 mt-sm-5">
                <div className="col-md-10">
                  <div className="row ">
                    <div className="col-md-12 pb-5">
                      <form onSubmit={handleSubmit}>
                        <Tabs
                          defaultActiveKey="home"
                          id="uncontrolled-tab-example"
                          className="mb-0 mb-sm-3 buy-tabs justify-content-center "
                          onSelect={(key) => setActiveTab(key)}
                          activeKey={activeTab}
                        >
                          <Tab
                            eventKey="home"
                            title={
                              <React.Fragment>
                                <div className="d-flex align-items-center buy-boxcontent">
                                  <p>1</p>
                                  <h6> Add Details </h6>
                                  <img
                                    src="/images/arrow-rightblack.png"
                                    alt=""
                                  />
                                </div>
                              </React.Fragment>
                            }
                          >
                            {activeTab === "home" && (
                              <AddAdvertisement
                                formikProp={formik}
                                tabSet={setActiveTab}
                              />
                            )}
                          </Tab>

                          <Tab
                            eventKey="preview"
                            title={
                              <React.Fragment>
                                <div className="d-flex align-items-center buy-boxcontent buy-boxcontentnew">
                                  <p>2</p>
                                  <h6> Preview </h6>
                                </div>
                              </React.Fragment>
                            }
                          >
                            {activeTab === "preview" && (
                              <PreviewAdverstisement formikProp={formik} />
                            )}
                          </Tab>

                          <Tab
                            eventKey="payment"
                            title={
                              <React.Fragment>
                                <div className="d-flex align-items-center buy-boxcontent buy-boxcontentnew">
                                  <p>3</p>
                                  <h6> Payment </h6>
                                </div>
                              </React.Fragment>
                            }
                          ></Tab>
                        </Tabs>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AdvertisementForm;
