import React, { useEffect, useState } from "react";
// import Header from '../include/header';
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import Footer from "../include/footer";
import Header from "../include/header";
import FooterBottam from "../../components/FooterBottam/FooterBottam";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [contactUsData, setContactUsData] = useState([]);

  const navigate = useNavigate();

  const [state, setState] = useState({
    name: "",
    mobile_no: "",
    email: "",
    location: "",
    company_address: "",
    website: "",
    description: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqData = {
      name: state?.name,
      mobile_no: state?.mobile_no,
      email: state?.email,
      location: state?.location,
      company_address: state?.company_address,
      website: state?.website,
      description: state?.description,
    };
    // return
    const { success, message } = await apiRequest({
      endUrl: API_END_URLS.postContactUs,
      method: "POST",
      body: reqData,
    });
    if (success) {
      setState({
        name: "",
        mobile_no: "",
        email: "",
        location: "",
        company_address: "",
        website: "",
        description: "",
      });
      showMessage(message, "success");
      navigate('/')
    } else {
      showMessage(message, "error");
    }
  };

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter((module) => module.module === "contact_us_page");
      setContactUsData(filter);
    } else {
      showMessage(message);
    }
  };


  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    SeoCall()
    fatchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <Header></Header>

      <section className="about-section contact-section">
        <div className="about-bannertext">
          <h4 className="goldman">Contact Us</h4>
        </div>
      </section>

      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row justify-content-center mt-sm-4">
            <div className="col-md-11">
              <div className="contact-bg">
                <div className="contact-frombg">
                  <div className="row justify-content-center">
                    <div className="col-md-11">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div class="mb-sm-5 mb-3 mr-sm-4">
                              <input
                                type="text"
                                class="form-control contact-input"
                                placeholder="Name *"
                                name="name"
                                onChange={(e) => setState({ ...state, name: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="mb-sm-5 mb-3 ml-sm-4">
                              <input
                                type="text"
                                class="form-control contact-input"
                                placeholder="Phone No. *"
                                name="mobile_no"
                                onChange={(e) => setState({ ...state, mobile_no: e.target.value })}
                                
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="mb-sm-5 mb-3 mr-sm-4">
                              <input
                                type="email"
                                class="form-control contact-input"
                                placeholder="Email *"
                                name="email"
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="mb-sm-5 mb-3 ml-sm-4">
                              <input
                                type="text"
                                class="form-control contact-input"
                                placeholder="Location *"
                                name="location"
                                onChange={(e) => setState({ ...state, location: e.target.value })}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="mb-sm-5 mb-3 mr-sm-4">
                              <input
                                type="text"
                                class="form-control contact-input"
                                placeholder="Company Address"
                                name="company_address"
                                onChange={(e) => setState({ ...state, company_address: e.target.value })}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div class="mb-sm-5 mb-3 ml-sm-4">
                              <input
                                type="text"
                                class="form-control contact-input"
                                placeholder="Website"
                                name="website"
                                onChange={(e) => setState({ ...state, website: e.target.value })}

                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control contact-input"
                                placeholder="Description *"
                                name="description"
                                onChange={(e) => setState({ ...state, description: e.target.value })}

                              />
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-center mt-sm-5 mt-3">
                          <div className="col-md-3 col-7">
                            <button
                              type="submit"
                              class="btn review-btn new-submitbtn btn-block"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>

                      <div className="row justify-content-center mt-sm-5 mt-4">
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-5 mb-3 mb-sm-0">
                              <div className="d-flex align-items-center">
                                <img
                                  src="images/forum.svg"
                                  className="mr-3"
                                  alt=""
                                />
                                <p>{contactUsData[0]?.value}</p>
                              </div>
                            </div>
                            <div className="col-md-4 mb-3 mb-sm-0">
                              <div className="d-flex align-items-center">
                                <img
                                  src="images/call.svg"
                                  className="mr-3"
                                  alt=""
                                />
                                <p>{contactUsData[1]?.value}</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex align-items-center">
                                <img
                                  src="images/location.svg"
                                  className="mr-3"
                                  alt=""
                                />
                                <p>{contactUsData[2]?.value}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterBottam></FooterBottam>
      <Footer></Footer>

      {/* <Header></Header> */}

      {/* <section className="py-4 py-sm-5 contactUs-section ">
                <div className="container">
                    <div className="row justify-content-center mt-5">
                        <div className="col-md-11">
                            <div className="row align-items-center">
                                <div className="col-md-5 mb-2 mb-sm-0">
                                    <div className="contact-leftimg mt-3">
                                        <img src="images/contact-left.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <form onSubmit={handleSubmit}>
                                        <div className="contact-us">
                                            <h1>Contact Us</h1>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" name="name" value={state?.name} onChange={(e) => setState({ ...state, name: e.target.value })} className="form-control contact-input" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        maxlength="12"
                                                        name="mobile_no"
                                                        value={state?.mobile_no}
                                                        onChange={(e) => setState({ ...state, mobile_no: e.target.value })}
                                                        className="form-control contact-input"
                                                        placeholder="Phone No."
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" name="email" value={state?.email} onChange={(e) => setState({ ...state, email: e.target.value })} className="form-control contact-input " placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" name="location" value={state?.location} onChange={(e) => setState({ ...state, location: e.target.value })} className="form-control contact-input " placeholder="Location" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" name="company_address" value={state?.company_address} onChange={(e) => setState({ ...state, company_address: e.target.value })} className="form-control contact-input " placeholder="Company Address" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" name="website" value={state?.website} onChange={(e) => setState({ ...state, website: e.target.value })} className="form-control contact-input " placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea name="description" value={state?.description} onChange={(e) => setState({ ...state, description: e.target.value })} className="form-control contact-input" placeholder="Description" rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row justify-content-center text-center mt-2 mb-3 mb-sm-0">
                                            <div className="col-md-4 col-8">
                                                <button type="submit" className=" search-btn btn-block ">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="row mt-2 mt-sm-4">
                                <div className="col-md-5 col-8 text-left text-sm-center">
                                    <div className="conatct-socialimg">
                                        <a href="#"><img src="images/facebook.png" alt="" /></a>
                                        <a href="#"><img src="images/linkedin.png" alt="" /></a>
                                        <a href="#"><img src="images/twitter.png" alt="" /></a>
                                        <a href="#"><img src="images/youtube.png" alt="" /></a>
                                        <a href="#"><img src="images/instagram.png" alt="" /></a>
                                    </div>
                                </div>
                                <div className="col-md-7 col-4 text-right mt-2 mt-sm-4">
                                    <a href="#"><img src="images/uparrow.png" alt="" /></a>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-7 col-lg-5 col-12">
                                    <div className="d-block d-sm-flex justify-content-between">

                                        <div className="location-contact mb-2 mb-sm-0">
                                            <p><img src="images/forum.png" alt="" /> Hello@sparess.com</p>
                                        </div>


                                        <div className="location-contact mb-2 mb-sm-0">
                                            <p><img src="images/contact-call.png" alt="" /> +09876543210</p>
                                        </div>


                                        <div className="location-contact mb-2 mb-sm-0">
                                            <p><img src="images/location-on.png" alt="" /> Hello@sparess.com</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
    </>
  );
}

export default ContactUs;
