import React, { useEffect, useState } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import { API_END_URLS } from "../../config/endUrls";
import { apiRequest } from "../../config/apiRequest";
import { showMessage } from "./toast";
import { _cookie_policy } from "../../config/constants";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function CookiePolicy() {
  const [data, setData] = useState([]);
  const navigate = useNavigate()

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter((module) => module.module === "cookie_policy");
      setData(filter);
    } else {
      showMessage(message);
    }
  };

  const getPrivacyPolicy = (type) => {
    let cookiePolicy = data?.filter((item) => {
      return item?.key === type;
    });
    if (cookiePolicy.length) {
      return cookiePolicy[0].value;
    } else {
      return;
    }
  };

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname,
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    SeoCall();
    fatchData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <div class="innerheader">
        <Header />
      </div>
      <section className="py-sm-5 py-4">
        <div className="container">
          
          <div className="row mt-sm-3 mt-2 align-items-center">
            <div className="col-md-1">
            <span className="back-boxarrow">
                <img
                  src="/images/trun-back.png"
                  alt="/"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
            <div className="col-md-10">
              <div className="terms-headabout text-center">
              <h2 className="goldman">Cookies Policy</h2>
               </div>
            </div>
          </div>

          <div className="row mt-sm-3 mt-0">
            <div className="col-md-12">
              <div className="terma-conditions">
                <div
                  dangerouslySetInnerHTML={{
                    __html: getPrivacyPolicy(_cookie_policy.cookiePolicy),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </React.Fragment>
  );
}

export default CookiePolicy;
