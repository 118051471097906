import React from 'react'
import { WhatsappIcon, WhatsappShareButton } from 'react-share'

export default function Whatsaap() {
    return (
        <>
            <WhatsappShareButton
                url={'https://web.whatsapp.com/'}
            >
                <WhatsappIcon size={32} className='mr-2' round />
            </WhatsappShareButton>
        </>
    )
}
