import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import Sparessheader from "../../../include/sparess-header";
// import SparessSidemenu from "../../../include/sparess-sidemanu";
import { apiRequest } from "../../../../config/apiRequest";
import { API_END_URLS } from "../../../../config/endUrls";
import { showMessage } from "../../../../helpers/toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fileUploadToS3 } from "../../../../helpers/utils";
import { S3Bucket } from "../../../../config/constants";
import SparessSidemenu from "../../../include/sparess-sidemanu";
import Sparessheader from "../../../include/sparess-header";
import moment from "moment";
import { getAddressFromLatLng, getLatLng } from "../../../../helpers/location";
import PostingManagerSideMenu from "../../../include/postingManagerSideMenu";
import { setGlobalState } from "../../../../redux/dispatch";

function SellerProductCreate() {
  const today = new Date();
  const params = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [imageDelete, setImageDelete] = useState([]);
  const [profile, setProfile] = useState({});

  const [state, setState] = useState({
    data: {},
    category_id: "",
    sub_category: "",
    title: "",
    brand: "",
    description: "",
    location: "",
    date: "",
    unit_price: "",
    qty: "",
    rate: "",
    currency: "",
    amount: "",
    status: "",
    radio: true,
    productType: 0,
  });

  const handlelocation = () => {
    getLatLng((err, { lat, lng }) => {
      if (err) return;
      // setLongitude(lat, lng);
      getAddressFromLatLng(lat, lng, (err, resp) => {
        if (err) return;
        setState({ ...state, location: resp?.address });
      });
    });
  };

  const getCategories = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.allCategories,
      method: "get",
      token: true,
    });
    if (success) {
      setCategories(data);
    } else {
      showMessage(message);
    }
  };

  const getProductsCategoryWise = async (category_id) => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.getCategoryByProduct,
        method: "get",
        token: true,
        params: { category_id },
      });
      if (success) {
        setProducts(data);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: SellerProductCreate.jsx:79 ~ getProductsCategoryWise ~ err:",
        err
      );
    }
  };

  const getDraftAndUpdateProduct = async (req, res) => {
    try {
      setGlobalState({ loader: true });
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.getProductDraftUpdate,
        method: "get",
        token: true,
        params: { id: params.id },
      });
      if (success) {
        setState({ ...state, ...data?.getProduct });
      } else {
        showMessage(message);
      }
      setGlobalState({ loader: false });
    } catch (err) {
    }
  };

  async function createProduct(status) {
    const fileUploadData = [];
    const fileData = new FormData();
    let fileJson = {};
  
    if (status !== "draft") {
      if ((!state?.ProductImages && !newImages) || newImages?.length === 0) {
        showMessage("Please select product images.", "error");
        return;
      }
  
      newImages.forEach((file) => {
        const filePath = "product/" + file.name;
        fileUploadData.push(filePath);
        fileJson[file.name] = filePath;
        fileData.append("file", file);
      });
  
      fileData.append("jsondata", JSON.stringify(fileJson));
    }
  
    let img = [];
  
    if (newImages?.length > 0) {
      try {
        const response = await apiRequest({
          endUrl: API_END_URLS.fileUpload,
          method: "post",
          body: fileData,
          headerType: "file",
        });
  
        if (response?.success) {
          if (typeof response.data === "string") {
            img = [response.data];
          } else if (Array.isArray(response.data)) {
            img = response.data;
          }
        } else {
          showMessage("Image upload failed.", "error");
          return;
        }
      } catch (error) {
        showMessage("Error during image upload.", "error");
        return;
      }
    } else {
      img = state?.ProductImages || [];
    }
  
    const reqData = {
      category_id: state.category_id,
      sub_category: state.sub_category,
      title: state.title,
      brand: state.brand,
      description: state.description,
      location: state.location,
      date: state.date,
      unit_price: state.unit_price,
      qty: state.qty,
      day_rate: state.day_rate,
      currency: state.currency,
      amount: state.amount,
      status: status,
      images: img,
      product_type: state.productType,
    };
    setGlobalState({ loader: true });
  
    try {
      const { success, data, message } = await apiRequest({
        endUrl: params.id ? API_END_URLS.putProduct : API_END_URLS.postProduct,
        token: true,
        method: params.id ? "PUT" : "POST",
        body: reqData,
        ...(params?.id && { params: { id: params.id } }),
      });
  
      if (success) {
        setState({ ...state, data: data.product });
        showMessage(message, "success");
  
        setState({
          ...state,
          category_id: "",
          sub_category: "",
          title: "",
          brand: "",
          description: "",
          location: "",
          date: "",
          price: "",
          qty: "",
          rate: "",
          currency: "",
          amount: "",
          status,
          radio: false,
          product_type: 0,
        });
  
        setTimeout(() => {
          navigate("/seller/posting/products");
        }, 2000);
      } else {
        showMessage(message, "error");
      }
    } catch (error) {
      showMessage("An error occurred during product creation.", "error");
    } finally {
      setGlobalState({ loader: false });
    }
  }
  



  const handleImages = async (e) => {
    let files = Array.from(e.target.files);

    let image = [];

    let totalImages = files.length;

    if (
      profile?.SubscriptionAcceptance?.subscription_type === "Standard Plan"
    ) {
      if (totalImages <= 2) {
        for (let img of files) {
          image.push(img);
        }
        for (let img of newImages) {
          image.push(img);
        }
        setNewImages(image);
      } else {
        showMessage("You can not upload more than 2 images", "error");
      }
    } else {
      if (totalImages <= 5) {
        for (let img of files) {
          image.push(img);
        }
        for (let img of newImages) {
          image.push(img);
        }
        setNewImages(image);
      } else {
        showMessage("You can not upload more than 5 images", "error");
      }
    }
  };

  const handleDeleteImage = async (id) => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.DeleteProductImage + id,
      method: "delete",
    });
    if (success) {
      setImageDelete(data);
      getDraftAndUpdateProduct();
    } else {
      getDraftAndUpdateProduct();

      showMessage(message);
    }
  };

  const handleImgeRemove = async (name, id) => {
    if (id === undefined) {
      let images = [];
      newImages.map((item, index) => {
        if (item.name !== name) {
          images.push(item);
        }
      });
      setNewImages(images);
    } else {
      await handleDeleteImage(id);
    }
  };

  const fecthProfileData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setProfile(data?.planInfo);
    } else {
      showMessage(message);
    }
  };

  const amountCal = () => {
    let currency = 23;
    let Quantity = state?.qty;
    setState({
      ...state,
      amount: Quantity * currency,
    });
  };

  useEffect(() => {
    fecthProfileData();
    getCategories();
    getDraftAndUpdateProduct();
  }, []);

  useEffect(() => {
    state?.category_id && getProductsCategoryWise(state?.category_id);
  }, [state?.category_id]);

  useEffect(() => {
    amountCal();
  }, [state?.qty]);

  return (
    <>
      <div class="container-fluid" id="seller-panel">
        <div class="row">
          <div class="col-md-3 pr-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Sparessheader></Sparessheader>
              </div>
              <div class="pageinnner-padding padding-rightnone">
                <div className="row">
                  <PostingManagerSideMenu />

                  <div className="col-md-9 p-sm-0 ">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="right-contentbox">
                        <div class="posting-box">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label class="post-label">Catagory</label>
                                    <div className="d-flex align-items-center">
                                      {categories?.map((item) => {
                                        return (
                                          <div class="category-gap-radio">
                                            <input
                                              class="form-check-input with-gap"
                                              type="radio"
                                              name="categories"
                                              id={item?.id}
                                              value={item?.id}
                                              checked={
                                                item?.id == state?.category_id
                                              }
                                              onClick={(e) => {
                                                getProductsCategoryWise(
                                                  e.target.value
                                                );
                                                setState({
                                                  ...state,
                                                  category_id: Number(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                            />
                                            <label
                                              class="form-check-label"
                                              for={item?.id}
                                            >
                                              {item?.category_name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label class="post-label">
                                      Products Name
                                    </label>
                                    <select
                                      className="form-control post-input"
                                      name="title"
                                      value={state.title}
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          title: e.target.value,
                                        })
                                      }
                                    >
                                      <option value=""> Select</option>
                                      {products.map((item, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={item?.id}
                                            selected={state?.title}
                                          >
                                            {item?.product_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <small class="small-text">
                                      Do not exceed 20 characters while entering
                                      product name
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label class="post-label">
                                      Sub catagory
                                    </label>
                                    <div className="d-flex align-items-center">
                                      <div class="category-gap-radio">
                                        {["New Product", "Used Product"].map(
                                          (value, index) => (
                                            <>
                                              <input
                                                checked={
                                                  value == state?.sub_category
                                                }
                                                class="form-check-input with-gap"
                                                type="radio"
                                                name="sub_category"
                                                id={`sub_category${value}`}
                                                value={state?.sub_category}
                                                onClick={(e) =>
                                                  setState({
                                                    ...state,
                                                    sub_category: value,
                                                  })
                                                }
                                              />
                                              <label
                                                class="form-check-label"
                                                for={`sub_category${value}`}
                                              >
                                                {value}
                                              </label>
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-11">
                                  <div class="form-group">
                                    <label class="post-label">
                                      {" "}
                                      Product Make{" "}
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control post-input"
                                      placeholder="Suzuki"
                                      name="brand"
                                      value={state?.brand}
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          brand: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className= "row">
                            <div className="col-md-6">
                              <div className="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label class="post-label">
                                      Descriptions{" "}
                                      <img
                                        src="/images/info-grey-icon.png"
                                        class="ml-3 ml-sm-1 ml-lg-3"
                                        alt="/"
                                      />
                                    </label>
                                    <textarea
                                      name="description"
                                      class="form-control post-input"
                                      rows="5"
                                      value={state?.description}
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          description: e.target.value,
                                        })
                                      }
                                    ></textarea>
                                    <small class="small-text">
                                      Do not exceed 150 characters while
                                      entering product name
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="row">
                                <div class="col-md-12">
                                  <div className="form-group">
                                    <label class="post-label">
                                      {" "}
                                      Products Image{" "}
                                    </label>
                                    <div className="row noflexwrap">
                                      {newImages
                                        ?.concat(state?.ProductImages || [])
                                        .map((item, index) => (
                                          <div className="col-md-6 mb-2">
                                            <img
                                              src="/images/cross-icon.png"
                                              alt=""
                                              onClick={() =>
                                                handleImgeRemove(
                                                  item?.name,
                                                  item?.id
                                                )
                                              }
                                            />
                                            <div class="browser-post">
                                              <img
                                                style={{
                                                  margin: 10,
                                                  maxWidth: "150px",
                                                }}
                                                src={
                                                  typeof item?.image_url ===
                                                  "string"
                                                    ? item?.image_url
                                                    : URL.createObjectURL(item)
                                                }
                                                key={index}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      <div className="col-md-6 mb-2">
                                        <div class="browser-post">
                                          <div class="text-center">
                                            <p>
                                              <div class="upload-btn-wrapper new-postwrapper">
                                                <button class="uploadBtn post-clickbtn btn-block">
                                                  <img
                                                    src="/images/upload-icon.png"
                                                    alt="/"
                                                  />
                                                </button>
                                                <input
                                                  type="file"
                                                  multiple={true}
                                                  id="images"
                                                  onChange={handleImages}
                                                  accept="image/*"
                                                />
                                              </div>
                                              Upload
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {profile?.SubscriptionAcceptance
                                      ?.subscription_type ===
                                    "Standard Plan" ? (
                                      <small class="small-text">
                                        You can add max 2 images and max image
                                        size should be 1 MB/Image.
                                      </small>
                                    ) : (
                                      <small class="small-text">
                                        You can add max 5 images and max image
                                        size should be 1 MB/Image.
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group">
                                <label class="post-label">
                                  {" "}
                                  Product Location{" "}
                                </label>
                                <div className="location-group position-relative">
                                  <input
                                    type="text"
                                    class="form-control post-input"
                                    placeholder="Enter Product Location"
                                    name="location"
                                    value={state?.location}
                                  />
                                  <img
                                    src="/images/Location.png"
                                    alt="/"
                                    onClick={() => handlelocation()}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-7">
                                  <div class="form-group">
                                    <label class="post-label">
                                      Product Date{" "}
                                    </label>
                                    <input
                                      type="date"
                                      id="date"
                                      name="date"
                                      className="form-control post-input "
                                      value={moment(state?.date).format(
                                        "YYYY-MM-DD"
                                      )}
                                      min={today.toISOString().split("T")[0]}
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          date: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div class="form-group">
                                    <label class="post-label">
                                      {" "}
                                      Unit Price{" "}
                                    </label>
                                    <input
                                      name="unit_price"
                                      value={state?.unit_price}
                                      type="text"
                                      className="form-control post-input"
                                      placeholder="Enter Unit Price"
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          unit_price: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6">
                                  <div class="form-group">
                                    <label class="post-label">Quantity </label>
                                    <input
                                      name="qty"
                                      value={state?.qty}
                                      type="text"
                                      className="form-control post-input"
                                      placeholder="Enter Qty"
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          qty: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div class="form-group">
                                    <label class="post-label">Currency </label>
                                    <select
                                      name="currency"
                                      className="form-control post-input "
                                      onChange={(e) =>
                                        setState({
                                          ...state,
                                          currency: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">Select</option>
                                      {["AED"].map((element, index) => (
                                        <option
                                          key={index}
                                          value={element}
                                          selected={element == state?.currency}
                                        >
                                          {element}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row align-items-center">
                                <div className="col-md-6">
                                  <div class="form-group">
                                    <label class="post-label"> Amount </label>
                                    <input
                                      name="amount"
                                      value={state?.amount}
                                      type="number"
                                      className="form-control post-input"
                                      placeholder="Enter Amount"
                                      onChange={({ target }) =>
                                      setState({
                                        ...state,
                                        amount: target.value,
                                      })
                                    }
                                    disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {state?.status === "active" ||
                          state?.status === "draft" ? (
                            <div class="text-center mt-4 pb-4">
                              <button
                                type="button"
                                name="status"
                                value="active"
                                onClick={({ target }) =>
                                  createProduct(target.value)
                                }
                                className="btn sparessborder-btn new-cancel  mr-3 "
                              >
                                Update Product
                              </button>
                            </div>
                          ) : (
                            <div class="text-center mt-4 pb-4">
                              <button
                                type="button"
                                name="status"
                                value="draft"
                                onClick={({ target }) =>
                                  createProduct(target.value)
                                }
                                className="btn sparessborder-btn new-cancel  mr-3 "
                              >
                                Save Product
                              </button>
                              <button
                                type="button"
                                name="status"
                                value="active"
                                onClick={({ target }) =>
                                  createProduct(target.value)
                                }
                                className="btn sparessfill-btn new-save"
                              >
                                Add Product
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellerProductCreate;
