import React from "react";

function FooterBottam() {
  return (
    <React.Fragment>
      <section className="my-4 my-sm-5">
        <div className="container">
          <div className="app-section postion-relative">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4  mb-3 mb-sm-0 col-8 text-center">
                <img src="/images/app-left-img1.png" alt="" />
              </div>
              <div className="col-md-7">
                <div className="app-qrsec">
                  <div className="row align-items-center">
                    <div className="col-md-6 pr-sm-0 mb-3 mb-sm-0">
                        <h6 className="goldman">Stay Tuned</h6>
                      <h4 className="goldman">Our New App Coming Soon!</h4>
                    </div>
                    <div className="col-md-3 col-6">
                      <img src="/images/apple-store.svg" alt="" />
                      <img
                        src="/images/google-play.svg"
                        className="mt-3"
                        alt=""
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="qr-boximg text-right">
                        <img src="/images/qr.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default FooterBottam;
