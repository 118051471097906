import AgoraRTC from "agora-rtc-sdk-ng";
import { APPCERTIFICATE, APPID, EXPTIME } from "../config/constants";
import { Navigate, useNavigate } from "react-router-dom";

/* Real Time Comunincation Variable */
let rtc = {
  localAudioTrack: null,
  localVideoTrack: null,
  client: null,
};

let fullScreenEnable = false;
let screenClient = null;
let screenTrack;
let appId = APPID;

async function roomEnable(isVideoCall = true) {
  try {
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    rtc.client.on("user-published", async (user, mediaType) => {
      await rtc.client.subscribe(user, mediaType);

      if (mediaType === "video" && isVideoCall) {
        const remoteContainer = document.getElementById("rm-container");
        const remoteVideoTrack = user.videoTrack;
        remoteVideoTrack.play(remoteContainer);
        console.log("host --", user);
      }
      if (mediaType === "audio") {
        console.log("Audio track received. Playing...");

        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });

    rtc.client.on("user-unpublished", (user, mediaType) => {
      try {
        if (mediaType === "video") {
          /* Show For wating */
          const div = document.querySelector(".customer-video-div");
          div.id = "guest-user";
          div.innerText = "Waiting ....";
        }
        if (mediaType === "audio") {
        }
      } catch (err) {}
    });
  } catch (error) {
    console.error("Error in roomEnable: >>>>>>>>>>>roomEnable", error);
    alert(error);
  }
}

/* PinInOut Function*/
function pinInOut(e) {
  let div = document.getElementById(e.target.id).parentElement.parentElement
    .parentElement;
  if (div.style.width === "120px") {
    div.style.width = "202px";
    div.style.height = "248px";
    div.style.objectFit = "cover";
  } else {
    div.style.width = "120px";
    div.style.height = "120px";
    div.style.objectFit = "cover";
  }
}

/* micController function */
async function micControl(val) {
  try {
    await rtc.localAudioTrack.setEnabled(val);
  } catch (err) {}
}

/* VideoController */
async function videoControl(val) {
  try {
    await rtc.localVideoTrack.setEnabled(val);
  } catch (err) {}
}

/* Volume Controller */
async function volumeControl(val) {
  try {
    await rtc.localAudioTrack.setVolume(parseInt(val));
  } catch (err) {}
}

/* Leave Room */

async function leaveRoom() {
  try {
    rtc.localAudioTrack.close();
    rtc.localVideoTrack.close();
    await screenTrack.close();
    await rtc.client.leave();
    return true;
  } catch (err) {}
}

async function joinRoom({ appId, channel, token, uid, isVideoCall = true }) {
  try {
    await rtc.client.join(appId, channel, token, uid);
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

    if (isVideoCall) {
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
      const localContainer = document.getElementById("lc-container");
      rtc.localVideoTrack.play(localContainer);
    } else {
      await rtc.client.publish([rtc.localAudioTrack]);
    }

    return { isStart: true };
  } catch (err) {
    console.log("join err", err);
    return { isStart: err };
  }
}

function fullScreen() {
  try {
    if (fullScreenEnable) {
      fullScreenEnable = false;
      document.exitFullscreen();
    } else {
      fullScreenEnable = true;
      const ob = document.getElementById("full-screen");
      ob.requestFullscreen();
    }
  } catch (err) {
    console.log("🚀 ~ fullScreen ~ err:", err);
  }
}

export {
  roomEnable,
  joinRoom,
  micControl,
  videoControl,
  volumeControl,
  leaveRoom,
  fullScreen,
};
