import React, { useEffect, useState } from "react";
import Header from "../../include/header";
import Footer from "../../include/footer";
import { useNavigate, useParams, Link } from "react-router-dom";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../toast";
import { S3Bucket } from "../../../config/constants";
import { setGlobalState } from "../../../redux/dispatch";
import FooterBottam from "../../../components/FooterBottam/FooterBottam";
import { Helmet } from "react-helmet";
import { getCredentials } from "../../../helpers/auth";

function CategoryProductListing() {
  const navigate = useNavigate();
  const params = useParams();

  const [CategoryProductList, setcategoryProductList] = useState([]);
  const [CategoryProductList2, setcategoryProductList2] = useState([]);

  const [subCategory, setSubCategory] = useState("");

  const [filter, setFilter] = useState({
    category: params.id,
    subCategory: "",
    search: "",
  });

  const categoryWiseProduct = async () => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeaCategoryProductList,
        method: "get",
        params: params,
        query: { sub_category: filter?.subCategory, search: filter?.search },
      });
      setGlobalState({ loader: true });
      if (success) {
        setcategoryProductList(data);
        setcategoryProductList2(data);
      } else {
        showMessage(message);
        setGlobalState({ loader: false });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.js:50 ~ categoryWiseProduct ~ err:", err);
    }
    setGlobalState({ loader: false });
  };

  const handlePromoted = async (type) => {
    try {
      if (type === "all") {
        setcategoryProductList(CategoryProductList2);
      } else {
        const PromotedProduct = CategoryProductList2.filter(
          (item) => item.product_promotion === type
        );
        setcategoryProductList(PromotedProduct);
      }
    } catch (error) {
      console.log("🚀 ~ handlePromoted ~ error:", error);
    }
  };

  useEffect(() => {
    setGlobalState({ loader: true });

    const data = CategoryProductList2.filter((x) => {
      if (x?.sub_category === subCategory) return true;
    });

    setcategoryProductList(data);
    setGlobalState({ loader: false });
  }, [subCategory]);

  useEffect(() => {
    categoryWiseProduct();
  }, [filter]);

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname,
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    SeoCall();
  }, []);

  const handleConnect = async (id) => {
    try {
      const checkLoggedIn = getCredentials();
      if (!checkLoggedIn) {
        return showMessage("Please login");
      }
      let req = {
        product_id: id,
      };
      setGlobalState({ loader: true });
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.connect,
        method: "post",
        body: req,
      });
      if (success) {
        navigate("/seller/connect");
      } else {
        showMessage(message);
      }
      setGlobalState({ loader: false });
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <Header></Header>
      <section className="pt-sm-5 pt-4">
        <div className="container">
          <div className="row mt-sm-3 align-items-center">
            <div className="col-md-2 col-2">
              <span className="back-boxarrow">
                <img
                  src="/images/trun-back.png"
                  alt="/"
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
            <div className="col-md-9 col-10">
              <div className="top-headabout text-center">
                <h2 className="goldman">
                  {CategoryProductList[0]?.Category?.category_name} Products
                </h2>
              </div>

              <div className="top-headabout">
                <h6>{CategoryProductList[0]?.Category?.description}</h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <div class="form-group position-relative">
                <img
                  src="/images/graysearch-icon.svg"
                  className="search-icon"
                  alt=""
                />
                <input
                  type="email"
                  class="form-control product-input"
                  placeholder="Search for Product Id, catagory or something..."
                  value={filter?.search}
                  onChange={(e) => {
                    setFilter({ ...filter, search: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <button type="button" class="btn sconnect-btn mr-2">
                Search
              </button>
            </div>
          </div>

          <label className="category-mainlabel mt-sm-4 mt-3">Filter</label>
          <div className="row">
            <div className="col-md-8 mb-sm-0 mb-3">
              <div className="category-box">
                <div className="d-sm-flex d-block justify-content-between">
                  {[
                    "New Product",
                    "Used Product",
                    "Lease and Rental",
                    "Service",
                  ].map((item, index) => (
                    <div class="desgin-radio">
                      <input
                        class="with-gap"
                        type="radio"
                        name="client-selector2"
                        id={index}
                        onChange={(e) => setSubCategory(item)}
                      />
                      <label for={index} className="category-label">
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="category-box">
                <div className="d-flex justify-content-center">
                  <div class="desgin-radio mr-5">
                    <input
                      class="with-gap"
                      type="radio"
                      name="client-selector"
                      id="client2"
                      onClick={() => handlePromoted("All")}
                    />
                    <label for="client2" className="category-label">
                      All
                    </label>
                  </div>
                  <div class="desgin-radio">
                    <input
                      class="with-gap"
                      type="radio"
                      name="client-selector"
                      id="client3"
                      onClick={() => handlePromoted(1)}
                    />
                    <label for="client3" className="category-label">
                      Promoted
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="product-pricelabel d-sm-block d-none mt-sm-5 mt-3">
            <div className="row mt-sm-4 mt-3 pb-2">
              <div className="col-md-4">
                <p>Product</p>
              </div>
              <div className="col-md-2 text-center">
                <p>Price</p>
              </div>
              <div className="col-md-2 text-center">
                <p>Available</p>
              </div>
              <div className="col-md-2">
                <p>Action</p>
              </div>
            </div>
          </div>

          {CategoryProductList?.ProductList?.map((item) => {
            return (
              <>
                <div className="product-detailsbox mt-4">
                  <div className="row ">
                    <div className="col-md-6 mb-sm-0 mb-3">
                      <div className="row align-items-center">
                        <div className="col-md-3 mb-sm-0 mb-3">
                          <div className="product-detailsimgbox">
                            <Link to={`/range-products/${item?.id}`}>
                              {item?.ProductImages[0] ? (
                                <div className="table-firstimg">
                                  <img
                                    src={
                                      S3Bucket +
                                      item?.ProductImages[0]?.image_url
                                    }
                                    alt=""
                                  />
                                </div>
                              ) : (
                                "No Photo"
                              )}
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-7">
                          {item?.product_promotion === 1 && (
                            <span className="promoted-product">
                              <img
                                src="/images/reward.svg"
                                className="reward-img"
                                alt="/"
                              />
                              Promoted Product
                            </span>
                          )}

                          <Link to={`/range-products/${item?.id}`}>
                            <h4>
                              {" "}
                              {item?.CategoryProduct?.product_name} <br />{" "}
                              {item?.brand}
                            </h4>
                            <h6>{item?.description}</h6>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-4">
                      <Link to={`/range-products/${item?.id}`}>
                        <h3>{item?.amount}</h3>
                      </Link>
                    </div>
                    <div className="col-md-2 col-4">
                      <h3>{item?.qty}</h3>
                    </div>
                    <div className="col-md-2 mt-2 mt-sm-0">
                      <button
                        type="button"
                        class="btn contact-btn btn-block mt-sm-2"
                      >
                        Connect
                      </button>
                      <div className="row mt-3">
                        <div className="col-md-6 col-6 pr-sm-2">
                          <button
                            type="button"
                            class="btn contact-btn px-2  btn-block "
                          >
                            <img
                              src="/images/chat-green.svg"
                              alt="/"
                              onClick={() => handleConnect(item?.id)}
                            />
                          </button>
                        </div>
                        <div className="col-md-6 col-6 pl-sm-2">
                          <button
                            type="button"
                            class="btn contact-btn px-2 btn-block "
                            onClick={() => handleConnect(item?.id)}
                          >
                            <img src="/images/call-filled.svg" alt="/" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>

      <FooterBottam></FooterBottam>

      <Footer></Footer>
    </React.Fragment>
  );
}

export default CategoryProductListing;
