import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const VideoSlider = ({ videoUrls }) => {
  const sliderRef = useRef(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => {
      setCurrentVideoIndex(index);
    },
    autoplay: false, // Enable autoplay
    arrows: true,
  };

  useEffect(() => {
    const handleNextVideo = () => {
      const nextIndex = (currentVideoIndex + 1) % videoUrls.length;
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(nextIndex);
      }
    };

    // Clean up previous video element
    const videoElement = document.getElementById(`video-${currentVideoIndex}`);
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }

    // Autoplay current video
    const currentVideo = document.getElementById(`video-${currentVideoIndex}`);
    if (currentVideo) {
      currentVideo.muted = true; // Ensure muted for autoplay
      currentVideo.play()
        .then(() => {
          console.log(`Playing video ${currentVideoIndex}`);
        })
        .catch((error) => {
          console.error(`Error playing video ${currentVideoIndex}:`, error);
          handleNextVideo();
        });

      currentVideo.loop = false;

      currentVideo.onended = () => {
        if (currentVideoIndex === videoUrls.length - 1) {
          setCurrentVideoIndex(0);
          if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
          }
        } else {
          handleNextVideo();
        }
      };

      currentVideo.onerror = () => {
        console.error(`Playback error on video ${currentVideoIndex}`);
        handleNextVideo();
      };
    }

    return () => {
      if (currentVideo) {
        currentVideo.pause();
        currentVideo.currentTime = 0;
        currentVideo.onended = null;
        currentVideo.onerror = null;
      }
    };
  }, [currentVideoIndex, videoUrls]);

  return (
    <Slider ref={sliderRef} {...settings}>
      {videoUrls.map((videoUrl, index) => (
        <div key={index}>
          <video
            id={`video-${index}`}
            muted
            style={{ width: '100%', height: 'auto' }}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ))}
    </Slider>
  );
};

export default VideoSlider;