import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Buffer } from 'buffer';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { apiRequest } from "./config/apiRequest";
import { API_END_URLS } from "./config/endUrls";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";


const theme = createTheme({
  palette: {
    primary: {
      main: '#FF5501'
    }
  }
});

Buffer.from('anything', 'base64');
window.Buffer = window.Buffer || require("buffer").Buffer

require('bootstrap/dist/css/bootstrap.min.css');
require('./assets/css/material.scss');
// require('./assets/seller/css/icon-style.scss');
require('./assets/css/style.scss');
require('./assets/css/responsive.scss');

// require('./assets/frontend/css/material.scss');
// require('./assets/frontend/css/style.scss');
// require('./assets/frontend/css/responsive.scss');

  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

//   <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
//   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
