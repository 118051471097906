import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showMessage } from "../../../helpers/toast";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { Modal } from "react-bootstrap";

export default function AdvertisementPaymenDecline() {
  const [lgShow, setLgShow] = useState(true);

  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const qry = new URLSearchParams(window.location.search);
    let order_id = qry.get("orderId");

    let req = {
      redirect_status: "Decline",
      id: order_id,
    };

    const fetchData = async () => {
      const { message, success } = await apiRequest({
        endUrl: API_END_URLS.advertisementUpdateStatus,
        method: "POST",
        body: req,
      });
      if (success) {
        showMessage(message);
        setStatus(message);
      } else {
        showMessage(message);
      }
    };
    fetchData();
    const timer = setTimeout(() => {
      setLgShow(false);
      navigate("/");
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const onHide = () => {
    navigate("/");
  };

  return (
    <React.Fragment>
      <Modal
        className="scroll-pop"
        show={lgShow}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="p-4">
          <div className="payment-contentpop">
            <div className="row mb-4">
              <div className="col-md-12 text-center">
                <div className="payment-failed mb-sm-3 mb-2">
                  <img src="/images/card-payment.svg" alt="/" />
                </div>

                <h1>Payment Failed </h1>
                <p>Please Wait......</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
