import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../config/apiRequest";
import { API_END_URLS } from "../config/endUrls";
import { showMessage } from "../helpers/toast";

export default function PaymentRedirectSuccess() {
  const [lgShow, setLgShow] = useState(true);

  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const qry = new URLSearchParams(window.location.search);
    let order_id = qry.get("orderId");

    let req = {
      redirect_status: "Success",
      id: order_id,
    };
    const fetchData = async () => {
      const { message, success } = await apiRequest({
        endUrl: API_END_URLS.subscriptionPaymentUpdated,
        method: "POST",
        body: req,
      });
      if (success) {
        showMessage(message);
        setStatus(message);
        navigate("/");
      } else {
        showMessage(message);
      }
    };
    fetchData();

    const timer = setTimeout(() => {
      setLgShow(false);
      navigate("/");
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const onHide = () => {
    navigate("/");
  };

  return (
    <>
      <Modal
        className="success-pop-new"
        show={lgShow}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <div className="row justify-content-right">
            <div className="col-md-12"></div>
          </div>
          <button className="btn p-0" onClick={() => navigate("/")}>
            {" "}
            <img src="/images/cross-Icon.svg" alt="" />
          </button>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="payment-contentpop">
            <div className="row">
              <div className="col-md-12 text-center">
                <img src="/images/payment-successful.gif" alt="/" />
                <h1>{status}</h1>
                <p className="pt-5">Team will get back to you!</p>
                <p>Please Wait......</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
