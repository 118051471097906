// import React from 'react';
import React, { useEffect, useState } from 'react';
// import { Container } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSelector } from 'react-redux'

import Chart from "chart.js/auto";

import { Bar } from "react-chartjs-2";

import Slider from "react-slick";

import { Modal } from 'react-bootstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
// import Footer from '../include/footer';
import DealsProducts from '../../components/Homepage/DealsProducts';
import RecentProducts from '../../components/Homepage/RecentProducts';
import { apiRequest } from '../../config/apiRequest';
import { API_END_URLS } from '../../config/endUrls';
import { Pagination } from '@mui/material';
import { showMessage } from '../../helpers/toast';
import ReactStars from 'react-rating-stars-component';
import { S3Bucket } from '../../config/constants';
import moment from 'moment';
import { getCredentials } from '../../helpers/auth';

var sliderfirstsetting = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: false,
};

var servicesettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 3,
    autoplay: true,
    pauseOnHover: false,
    padding: '60px',
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};


function HomeNew() {

    const params = useParams()

    const [shows, setShows] = useState(false);
    const handleCloses = () => setShows(false);
    const handleShows = () => setShows(true);
    const [tabActive, setTabActive] = useState('allpost')

    const checkSubscribe = getCredentials()

    const navigate = useNavigate();

    let categoryArray = [
        {
            name: 'Marine Vessels and Equipment',
            backgroundImage: '/images/Add1.jpg',
            iconTransParent: '/images/add-min.png',
            iconSolid: '/images/deal-ship.png',
            catid: '',

        },
        {
            name: 'Offshore Vessels and Equipment',
            backgroundImage: '/images/Add5.jpg',
            iconTransParent: '/images/add-min2.png',
            iconSolid: '/images/Luxury.png',
            catid: '',
        },
        {

            name: 'Rental Equipment',
            backgroundImage: '/images/Add4.jpg',
            iconTransParent: '/images/add-minkey.png',
            iconSolid: '/images/keys.png',
            catid: '',
        },
        {

            name: 'Luxury Vessels and Crafts',
            backgroundImage: '/images/Add6.jpg',
            iconTransParent: '/images/add-min4.png',
            iconSolid: '/images/deal-new2.png',
            catid: '',
        },
        {

            name: 'Marine and Offshore Products',
            backgroundImage: '/images/Add7.jpg',
            iconTransParent: '/images/add-min5.png',
            iconSolid: '/images/Deal-new.png',
            catid: '',
        },
        {

            name: 'Marine and Offshore Sparess',
            backgroundImage: '/images/Add2.jpg',
            iconTransParent: '/images/add-min6.png',
            iconSolid: '/images/marine.png',
            catid: '',
        },
        {

            name: 'Maintenance & Support Services',
            backgroundImage: '/images/Add3.jpg',
            iconTransParent: '/images/add-min7.png',
            iconSolid: '/images/hand.png',
            catid: '',
        },


    ]

    const labels = ["Posting", "Annual Subscription", "Free Trial Days", "Annual Pus Notifications", "Posting Duratino Days", "Photo Uploads"];
    const data = {
        labels: labels,
        datasets: [
            {
                label: "Premiun",
                backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                data: [50, 150, 250, 350, 314, 150, 150],
                borderRadius: "10",
                barThickness: "8"
            },
            {
                label: "Standerd",
                backgroundColor: "#FF5501",
                borderColor: "#FF5501",
                borderRadius: "10",
                barThickness: "8",

                data: [50, 100, 200, 300, 500, 600, 95],
            },
        ],

    };


    const [products, setProducts] = useState([]);
    // const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    // console.log("categoriesData",categoriesData);
    const [subCategories, setSubCategories] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [noOfPages, setNoOfPages] = useState(0);


    const [banner, setBanner] = useState([]);
    const [bannerText, setBannerText] = useState("");
    const [bannerProperties, setBannerProperties] = useState([]);
    const [subscription, setSubscription] = useState([]);
    const [premiumPlan, setPremiumPlan] = useState([{}])
    const [deals, setDeals] = useState([]);
    const [whySparess, setWhySparess] = useState([]);



    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
    });


    const getRecords = async () => {
        const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.getCMSRecords, method: "get" })
        if (success) {
            setBannerText(data[0]["banner_text"])
            setBannerProperties(data[0]["banner_properties"])
            setSubscription(data[0]["subscription_plan"])
            setPremiumPlan(data[0]["premium_plan"])
            setDeals(data[0]["deals_and_promotions"])
            setWhySparess(data[0]["why_sparess"])
            setBanner(data[0]["img"])
        } else {
            showMessage(message)
        }
    }


    /* Footer State With Data Fetch Code */

    const [footerData, setFooterData] = useState({})

    const [location, setLocation] = useState([""])

    const fatchFooterData = async () => {
        const { data, message, success } = await apiRequest({ endUrl: API_END_URLS.getFooters, method: "get" })
        if (success) {
            setFooterData(data)
            setLocation(data.location)
        } else {
            showMessage(message)
        }
    }

    const imageMap = [
        "images/virified.png",
        "images/icon-park_oceanengine.png",
        "images/Vectorss.png",
        "images/carbon_connection-signal.png",
        "images/icon-park-outline_ad-product.png"
    ]

    const getProductListing = async () => {
        const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.getUserProducts, method: "get", query: query })
        setProducts(data.products.rows);
        setProductCount(data.products.count);
        setNoOfPages(Math.ceil(data.products.count / query.limit));
    }

    const getCategoryListing = async () => {
        const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.getAllCategories, method: "get" })

        categoryArray?.map((item1) => {
            data?.categories?.map((item) => {
                if (item.alias == item1.name) {
                    item1['catid'] = item.id;
                    item1['description'] = item.description;
                }
            })
            setCategoriesData(categoryArray)
        })

        setCategories(data.categories);
        // setProductCount(data.products.count);
        // setNoOfPages(Math.ceil(data.products.count / query.limit));
    }


    //Review & Rating 

    const [reviewData, SetReviewData] = useState([])


    const fetchReviewData = async () => {
        const { data, message, success } = await apiRequest({ endUrl: API_END_URLS.reviewsAll, method: "get" })
        if (success) {
            SetReviewData(data.review.rows)
        } else {
            showMessage(message)
        }
    }


    /* Blog Content Code */

    const [blog, setBlog] = useState([])

    const [allBlog, setAllBlog] = useState([]);

    const fetcBlogData = async (industries) => {
        setTabActive(industries)
        const { data, message, success } = await apiRequest({ endUrl: API_END_URLS.getBlogs, method: "get", query: { industries } })
        if (success) {
            setBlog(data)
        } else {
            showMessage(message)
        }
    }

    const allBlogData = async () => {
        const { data, message, success } = await apiRequest({ endUrl: API_END_URLS.getBlogs, method: "get", })
        if (success) {
            setAllBlog(data)
        } else {
            showMessage(message)
        }
    }


    useEffect(() => {
        getProductListing();
        getCategoryListing();
        getRecords();
        fatchFooterData();
        fetchReviewData();
        fetcBlogData();
        allBlogData()
    }, []);


    const renderBlogs = (item) => {
        return <div className="industries-text">
            <div className="row">
                {
                    blog.slice(1, 4).map((item, index) => {
                        console.log('itemss', item)
                        return <>
                            <div className="col-md-4 mb-3 mb-sm-0">
                                <div class="blogs-box blogs-boxbottomborder">
                                    <div class="blogs-imgbox">
                                        <img src={API_END_URLS.S3Bucket + item?.blog_image} alt="" />
                                    </div>

                                    <div class="padding-box">
                                        <h2>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item?.blog_title }}
                                            />
                                        </h2>
                                        {/* <p>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item?.blog_description }}
                                            />,
                                        </p> */}
                                    </div>
                                    <h6>
                                        <Link
                                            to={`/seller/get-one-blog/${item.id}`}
                                            state={{ activeTab: tabActive }}
                                        >Read More<img src="images/right-arrowhite.png" class="ml-2" alt="" />
                                        </Link>
                                    </h6>

                                    <div className="date-boxbordertop">
                                        <div className="row">
                                            <div className="col-md-6 col-6 border-right">
                                                <h5>{moment(blog.createdAt).format("MMM Do YY")}</h5>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <h5>{moment(blog.createdAt).format("MMM Do YY")}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    })
                }
            </div>

            <div className="red-border"></div>
            <div className="col-md-12 mt-2">
            </div>
        </div>
    }

    return (
        <>

            <Header></Header>

            <section className="position-relative">
                <Carousel className="pb-5 pb-sm-0 ">
                    {
                        banner.map((item, index) => {
                            return (<Carousel.Item>
                                <img className="d-block w-100 top-bgimg" src={S3Bucket + item} alt="" />
                            </Carousel.Item>)
                        })
                    }
                </Carousel>

                {/* <Carousel className="pb-5 pb-sm-0 ">
                {console.log(banner)}
                    <Carousel.Item>
                        <img
                            className="d-block w-100 top-bgimg"
                            src="images/herobg.png"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 top-bgimg"
                            src="images/hero-bg2.png"
                            alt="Second slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 top-bgimg"
                            src="images/hero-bg3.png"
                            alt="Third slide"
                        />

                    </Carousel.Item>
                </Carousel> */}
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row  justify-content-center">
                            <div className="col-md-8">
                                <div className="top-banner">
                                    <h2>{bannerText}</h2>
                                </div>

                                <div className="search-section d-flex">
                                    <input type="email" className="form-control serach-input" placeholder="Search for all content..." />
                                    <button type="button" className=" search-btn">Search</button>
                                </div>

                                <div className="component-text">
                                    <div className="row ">
                                        <div className="col-md-4 mb-1 mb-sm-0 pr-0">
                                            <p><img src="images/Component3.png" className="mr-2" alt="" />{bannerProperties[0]}</p>
                                        </div>
                                        <div className="col-md-4 mb-1 mb-sm-0 pr-0">
                                            <p><img src="images/arrow-bag.svg" className="mr-2" alt="" />{bannerProperties[1]}</p>
                                        </div>
                                        <div className="col-md-4 mb-1 mb-sm-0 pr-0">
                                            <p><img src="images/user-call.svg" className="mr-2" alt="" />{bannerProperties[2]}</p>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-2 mt-sm-3">
                                        <div className="col-md-7">
                                            <div className="row">
                                                <div className="col-md-6 mb-1 mb-sm-0 pr-0">
                                                    <p><img src="images/Component4.png" className="mr-2" alt="" />{bannerProperties[3]}</p>
                                                </div>
                                                <div className="col-md-6 pr-0">
                                                    <p><img src="images/Component5.png" className="mr-2" alt="" />{bannerProperties[4]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center trial-btn">
                                    <button type="button" className=" free-btn">30-Day Free Trial</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-foot">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-10">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <h1>Connecting Buyers & Sellers Across the World in Just One  Click</h1>
                                        <p>Download the App Now!</p>
                                    </div>
                                    <div className="col-md-2 mr-0 col-6">
                                        <div className="footbtn" onClick={handleShows}  >
                                            <div className="row align-items-center">
                                                <div className="col-md-3 pr-0 col-3"><img src="images/play-white.png" alt="" /></div>
                                                <div className="col-md-9 pl-2 col-9"><h5>Get it on <br /> <span>Google Play</span></h5></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 ml-0 col-6">
                                        <div className="footbtn" onClick={handleShows}  >
                                            <div className="row align-items-center">
                                                <div className="col-md-3 pr-0 col-3"><img src="images/apple-white.png" alt="" /></div>
                                                <div className="col-md-9 pl-2 col-9"><h5>Get it on <br /> <span>Apple Store</span></h5></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 d-none d-sm-block">

                                        <div className="carousel-qr">
                                            <img src="images/qrcode.png" alt="" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="py-4 py-sm-5" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="head-text un-head text-underline">
                                <Link to="/AdvertisementForm">
                                    <h1>Advertize Here</h1>
                                </Link>

                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-11">

                            <div className="row experiance-slide mt-3 mt-sm-5">
                                <div className="col-md-6">
                                    <Slider {...sliderfirstsetting}>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                                <div className="col-md-6">
                                    <Slider  {...sliderfirstsetting}>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="head-text">
                                <h1>Our Subscription Plan</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="subscription-plansec py-4 py-sm-5 my-4 my-sm-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="subscription-planleft">
                                <div className="row">
                                    <div className="col-md-6 pr-sm-0 mb-4 mb-sm-0">
                                        <div className="standard-plan premium-plan ">
                                            <h2><img src="images/Component-white.png" alt="" /> Standard Plan</h2>
                                            <div className="border-bottom"></div>

                                            <div className="standard-plancontent ">

                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                    <p>{subscription[0]?.text}</p>
                                                    <h1>{subscription[0]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{subscription[1]?.text}</p>
                                                    <h1>{subscription[1]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{subscription[2]?.text}</p>
                                                    <h1>{subscription[2]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{subscription[3]?.text}</p>
                                                    <h1>{subscription[3]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{subscription[4]?.text}</p>
                                                    <h1>{subscription[4]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{subscription[5]?.text}</p>
                                                    <h1>{subscription[5]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{subscription[6]?.text}</p>
                                                    <h1>{subscription[6]?.value}</h1>
                                                </div>
                                                <div className="text-center py-0 py-sm-3">
                                                    <Link to="/Planchoose"  > <button type="button" className="btn choose-advanced "> Choose Basic </button></Link>
                                                    {/* <Link to="/seller/subscription-choose-plans/standard"  > <button type="button" className="btn choose-advanced "> Choose Basic </button></Link> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6  pl-sm-0">
                                        <div className="standard-plan  new-premium">
                                            <h2><img src="images/Component.png" alt="" />Premium Plan</h2>
                                            <div className="border-bottom"></div>

                                            <div className="standard-plancontent">

                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                    <p>{premiumPlan[0]?.text}</p>
                                                    <h1>{premiumPlan[0]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{premiumPlan[1]?.text}</p>
                                                    <h1>{premiumPlan[1]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{premiumPlan[2]?.text}</p>
                                                    <h1>{premiumPlan[2]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{premiumPlan[3]?.text}</p>
                                                    <h1>{premiumPlan[3]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{premiumPlan[4]?.text}</p>
                                                    <h1>{premiumPlan[4]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{premiumPlan[5]?.text}</p>
                                                    <h1>{premiumPlan[5]?.value}</h1>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p>{premiumPlan[6]?.text}</p>
                                                    <h1>{premiumPlan[6]?.value}</h1>
                                                </div>
                                                <div className="text-center py-2 py-sm-3">
                                                    <Link to="/Planchoose"  > <button type="button" className="btn choose-btn "> Choose Basic </button></Link>
                                                    {/* <Link to="/seller/subscription-choose-plans/premium" > <button type="button" className="btn choose-btn "> Choose Advance </button></Link> */}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6> Note * :  Posting will be chargable @$1/Post <br /> Notifications chargable @$1/Notification </h6>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="subscription-planlright">
                                <h1>Subscribe Annually & Save More</h1>
                                <div>
                                    <Bar data={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-4 pb-sm-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-12 text-center">
                            <div className="head-text">
                                <Link to="/##"> <h1>Deals & Promotions</h1> </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row py-2 py-sm-5 justify-content-center"   >
                                {categoriesData?.map((itemCat, index) => {
                                    if (index == 0 || index == 1 || index == 2 || index == 3) {
                                        return (
                                            <div className="col-md-3 mb-3 mb-sm-0" >
                                                <DealsProducts data={itemCat} />
                                            </div>
                                        )
                                    }
                                }
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-0 mt-sm-5">
                        <div className="col-md-9 col-12">
                            <div className="row">
                                {categoriesData?.map((itemCat, index) => {
                                    if (index == 4 || index == 5 || index == 6) {
                                        return (
                                            <div className="col-md-4 mb-3 mb-sm-0">
                                                <DealsProducts data={itemCat} />
                                            </div>
                                        )
                                    }
                                }
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="py-0  py-sm-5">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12 text-center">
                            <div className="head-text">
                                <h1>Recently Added</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="row mt-4 mt-sm-5 align-items-center">

                                {categoriesData?.map((itemCat, index) => {
                                    if (index == 0 || index == 1 || index == 2 || index == 3) {
                                        return (
                                            <div className="col-md-3 col-6 mb-4">
                                                <RecentProducts data={itemCat} />
                                            </div>
                                        )
                                    }
                                })}
                            </div>

                            <div className="row justify-content-center ">
                                <div className="col-md-9">
                                    <div className="row">
                                        {categoriesData?.map((itemCat, index) => {
                                            if (index == 4 || index == 5 || index == 6) {
                                                return (
                                                    <div className="col-md-4  col-6 ">
                                                        <RecentProducts data={itemCat} />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-4 py-sm-5">
                <div className="container">

                    <div className="row ">
                        <div className="col-md-12 text-center">
                            <div className="head-text">
                                <h1>Range Of Products</h1>
                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center mt-4 ">
                        <div className="col-md-12">

                            <div className="main-box">
                                {products.map((product) => {
                                    return (

                                        <Link to={product.id ? "/product/view/" + product.id : ""}>
                                            <div className="boxborder" style={{ textTransform: "capitalize" }}>
                                                <p>{product.title ? product.title : " "}<br />{product.brand ? product.brand : " "}</p>
                                            </div>
                                        </Link>

                                    )
                                })}

                            </div>

                            <div className="row pt-4  pt-sm-5 justify-content-end">
                                <div className="text-right mr-4">

                                    {
                                        noOfPages ?
                                            <Pagination count={noOfPages} defaultPage={1} siblingCount={2} boundaryCount={2} variant="outlined" shape="rounded" color="primary" onChange={(e, page) => {
                                                setQuery({ ...query, page: page });
                                            }} />
                                            : ""
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <section className="pb-4 pb-sm-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="head-text">
                                <h1>Why Sparess</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4 mt-sm-5">

                        {
                            whySparess.map((item, index) => {
                                return <>
                                    <div className="col pl-0 pr-0">
                                        <div className="why-sparessbox">
                                            <div className="why-sparessbox-layer">
                                                <div className="why-sparessboximg">
                                                    <img src="images/virified.png" alt="" />
                                                    {/* <img src="images/icon-park_oceanengine.png" alt="" />
                                                    <img src="images/Vectorss.png" alt="" />
                                                    <img src="images/carbon_connection-signal.png" alt="" />
                                                    <img src="images/icon-park-outline_ad-product.png" alt="" /> */}
                                                </div>
                                                <h1>{item.text}</h1>
                                            </div>
                                            <div className="why-sparessboxbottomimg">
                                                <img
                                                    src={API_END_URLS.S3Bucket + item.image} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            })
                        }
                    </div>
                </div>
            </section>

            <section className="pb-4 pb-sm-5">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12 text-center">
                            <div className="head-text">
                                <h1>Blogs</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-4">
                        <div className="col-md-10">
                            <div className="row mt-3 mt-sm-5">
                                <div className="col-md-12">

                                    <Tabs
                                        defaultActiveKey={tabActive}
                                        id="uncontrolled-tab-example"
                                        className="mb-2 mb-sm-3"
                                        onSelect={(key) => fetcBlogData(key)}
                                    >
                                        <Tab activeKey={tabActive} eventKey="allpost" title="All Post" className='sadaf'>
                                            {renderBlogs()}
                                            {/* <div class="row">
                                                {
                                                    allBlog.slice(1, 4).map((item, index) => {
                                                        return <>
                                                            <div className="col-md-4 mb-3 mb-sm-0">
                                                                <div class="blogs-box blogs-boxbottomborder">
                                                                    <div class="blogs-imgbox">
                                                                        <img src={API_END_URLS.S3Bucket + item?.blog_image} alt="" />
                                                                    </div>

                                                                    <div class="padding-box">
                                                                        <h2>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{ __html: item?.blog_title }}
                                                                            /></h2>
                                                                        <p>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{ __html: item?.blog_description }}
                                                                            />,
                                                                        </p>
                                                                    </div>
                                                                    <h6>
                                                                        <Link to={`/seller/get-one-blog/${item.id}`}>Read More<img src="images/right-arrowhite.png" class="ml-2" alt="" />
                                                                        </Link>
                                                                    </h6>

                                                                    <div className="date-boxbordertop">
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-6 border-right">
                                                                                <h5>{moment(blog.createdAt).format("MMM Do YY")}</h5>
                                                                            </div>
                                                                            <div className="col-md-6 col-6">
                                                                                <h5>{moment(blog.createdAt).format("MMM Do YY")}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    })
                                                }
                                            </div> */}
                                        </Tab>

                                        <Tab eventKey="industries" title="Industries">
                                            {renderBlogs()}
                                        </Tab>
                                        <Tab eventKey="business" title="Business">
                                            {renderBlogs()}
                                        </Tab>
                                        <Tab eventKey="technology" title="Technology">
                                            {renderBlogs()}
                                        </Tab>

                                    </Tabs>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="py-4 py-sm-5 about-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-sechead">
                                <h1>Tell us about your experience with Sparess</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row mt-3 mt-sm-5 tell-slidenew ">
                                <div className="col-md-12">
                                    <Slider className="servslider" {...servicesettings}>
                                        {
                                            reviewData.map((item, key) => {
                                                return <div>
                                                    <div className="userreviwe">
                                                        <div className="row br align-items-center">
                                                            <div className="col-md-6 mb-2 mb-sm-0">
                                                                <div className="userreviwe-box">
                                                                    <div className="userreviwe-boxbg">
                                                                        <h1>{item?.User?.name}</h1>
                                                                        <h6>
                                                                            <ReactStars
                                                                                name="review_rating"
                                                                                value={item.review_rating}
                                                                                count={5}
                                                                                size={24}
                                                                                isHalf={true}
                                                                                emptyIcon={<i className="far fa-star"></i>}
                                                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                                fullIcon={<i className="fa fa-star"></i>}
                                                                                activeColor="#ffd700"
                                                                                edit={false}
                                                                            />
                                                                        </h6>
                                                                    </div>
                                                                    <div className="userreview-img">
                                                                        <img src={API_END_URLS.S3Bucket + item?.User?.profile_url || "images/pro-icon.png"} alt='' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 pl-3 pl-sm-0">
                                                                <h5>{item?.review_description}.</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center btntop-padding">
                        <button
                            type="button"
                            class=" review-btn"
                            onClick={() => navigate('/Experience')}
                        >
                            Review Sparess
                        </button>
                    </div>
                </div>
            </section>

            <section className="py-4 py-sm-5">
                <div className="container">


                    <div className="row py-4">
                        <div className="col-md-12">
                            <div className="scan-section">
                                <div className="row align-items-center">
                                    <div className="col-md-2 d-none d-sm-block">
                                        <img src="images/scanleft.png" className="img-fluid hand-imgleft" alt="" />
                                    </div>

                                    <div className="col-md-4 pl-3 pl-sm-0">
                                        <div className="scan-sectext">
                                            <h1>Try our Sparess App !!</h1>
                                            <p>Get Connected to Buyers,Sellers & Service  Providers  for your Marine & Offshore Needs,
                                                Anywhere and Anytime with your Mobile</p>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2  col-6  mt-sm-0 mt-2">
                                        <div className="footbtn mb-3 " onClick={handleShows}  >
                                            <div className="row align-items-center">
                                                <div className="col-md-3 pr-0 col-3"><img src="images/play-white.png" alt="" /></div>
                                                <div className="col-md-9 pl-2 col-9"><h5>Get it on <br /> <span>Google Play</span></h5></div>
                                            </div>
                                        </div>

                                        <div className="footbtn" onClick={handleShows}  >
                                            <div className="row align-items-center">
                                                <div className="col-md-3 pr-0 col-3"><img src="images/apple-white.png" alt="" /></div>
                                                <div className="col-md-9 pl-2 col-9"><h5>Get it on <br /> <span>Apple Store</span></h5></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6  mt-2 mt-sm-0">
                                        <div className="new-qrbox">
                                            <img src="images/scan-about.png" alt="" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>


            {/* <Footer
                fdata={footerData}
                address={location}
            >

            </Footer> */}

            <Modal show={shows} onHide={handleCloses} className="qr-popup">
                <Modal.Header closeButton >
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="qr-popupimg">
                                <img src="images/pop-qr.png" alt="" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary post-btn mr-2">BACK</button>
                    <button type="button" className="btn btn-login">PROCEED TO PAYMENT</button>
                   
                </Modal.Footer> */}
            </Modal>

        </>
    );
}

export default HomeNew;