import React from 'react'
import { TwitterIcon, TwitterShareButton } from 'react-share'

export default function Twitter() {
    return (
        <>
            <TwitterShareButton
                url={'https://twitter.com/i/flow/login'}
            >
                <TwitterIcon size={32} className='mr-2' round />
            </TwitterShareButton>
        </>
    )
}
