import React, { useEffect, useState } from 'react';
import Sparessheader from '../../include/sparess-header';
import SparessSidemenu from '../../include/sparess-sidemanu';
import { Link } from "react-router-dom";
import { apiRequest } from '../../../config/apiRequest';
import { API_END_URLS } from '../../../config/endUrls';
import { showMessage } from '../../frontend/toast';


function HelpDesk() {
    const [state, setState] = useState({
        name: "",
        title: "",
        message: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const reqData = {
            name: state?.name,
            title: state?.title,
            message: state?.message
        }
        // return
        const { success, data, message } = await apiRequest({ endUrl: API_END_URLS.postHelpDesk, method: "POST", token: true, body: reqData })
        if (success) {
            setState({
                name: "",
                title: "",
                message: "",
            })
            showMessage(message, "success")
            // navigate('/')
        }
        else {
            showMessage(message, "error")
        }
    }

    const [helpDeskData, setHelpDeskData] = useState({})

    const fatchData = async () => {
        const { data, message, success } = await apiRequest({ endUrl: API_END_URLS.getHelpDesk, method: "get" })
        console.log("🚀 ~ file: Helpdesk.js:46 ~ fatchData ~ data:", data)
        if (success) {
            setHelpDeskData(data)
        } else {
            showMessage(message)
        }

    }

    useEffect(() => {
        fatchData();
    }, [])

    return (
        <>
            <div class="container-fluid" id='seller-panel'>
                <div class="row">
                    <div class="col-md-3 d-none d-sm-block pr-0">
                        <SparessSidemenu></SparessSidemenu>
                    </div>
                    <div class="col-md-9 col-12 p-0">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Sparessheader></Sparessheader>
                            </div>

                            <div class="pageinnner-padding">

                                <div class="row">
                                    {/* <div class="col-md-3 p-0 border-right mb-2 mb-sm-0">
                                        <div class="content-menubox">
                                            <ul>
                                                <li><Link to="/seller/setting/services"> Services</Link></li>
                                                <li className="active"><Link to="/seller/setting/help-desk"> Help Desk </Link></li>
                                                <li><Link to="/seller/setting/change-password"> Change Password</Link></li>

                                            </ul>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <div className="settingpadding-left allover-padding">
                                            <h1> <img src="/images/Help-logo.png" alt='' /> </h1>

                                            <div className='d-flex justify-content-end mt-3'>
                                                <Link to="/seller/setting/change-password">
                                                    <button className='btn sparessfill-btn d-flex align-items-center'>
                                                        <span className='pass-group-bg mr-2'>
                                                            <img src='/images/lock-pass-icon.svg' alt='/'></img>
                                                        </span>
                                                        Password Change 
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className='border-bottom my-4'></div>
                                            <div className='row justify-content-center'>
                                                <div className='col-md-11'>
                                                    <div className="desk-from">
                                                        <div className="desk-formbg">
                                                            <div className="row">
                                                                <div className="col-md-5">
                                                                    <h6>{helpDeskData?.connect_with_us}</h6>
                                                                    {/* <p>{helpDeskData.text}.</p> */}
                                                                    <div
                                                                        dangerouslySetInnerHTML={{ __html: helpDeskData?.text }}
                                                                    />
                                                                    <div className="row mt-0 mt-sm-0 mt-lg-5">
                                                                        <div className="col-md-12">
                                                                            <div className="contact-box">
                                                                                <img src="/images/chat-icon-green.svg" alt='' />
                                                                                {helpDeskData?.email}
                                                                            </div>
                                                                            <div className="contact-box">
                                                                                <img src="/images/call-icon-green.svg" alt='' />
                                                                                {helpDeskData?.mobile}
                                                                            </div>
                                                                            <div className="contact-box">
                                                                                <img src="/images/loc-icon-green.svg" alt='' />
                                                                                {helpDeskData?.address}
                                                                            </div>

                                                                            <h4>
                                                                                <Link to={helpDeskData.facebook}><img src="/images/icon-facebook.svg" alt='' /></Link>
                                                                                <Link to={helpDeskData.linked}><img src="/images/icon-linked.svg" alt='' /></Link>
                                                                                <Link to={helpDeskData.twitter}><img src="/images/icon-twitter.svg" alt='' /></Link>
                                                                                <Link to={helpDeskData.youtube}><img src="/images/icon-youtube.svg" alt='' /></Link>
                                                                                <Link to={helpDeskData.instagram}><img src="/images/icon-insta.svg" alt='' /></Link>
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <div className="row justify-content-center mt-4 mt-sm-0">
                                                                        <div className="col-md-11 col-lg-10">
                                                                            <form onSubmit={handleSubmit}>
                                                                                <div className="form-contactsetting">
                                                                                    <div className="form-group">
                                                                                        <label className="edit-label" > Name </label>
                                                                                        <input type="text" name="name" value={state?.name} onChange={(e) => setState({ ...state, name: e.target.value })} className="form-control edit-input" />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="edit-label" > Title </label>
                                                                                        <input type="text" name="title" value={state?.title} onChange={(e) => setState({ ...state, title: e.target.value })} className="form-control edit-input" />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="edit-label" > Message </label>
                                                                                        <textarea name="message" value={state?.message} onChange={(e) => setState({ ...state, message: e.target.value })} className="form-control edit-input" rows="3"></textarea>
                                                                                    </div>
                                                                                    <div className="text-center">
                                                                                        <button type="submit" className="btn sparessfill-btn">Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default HelpDesk;