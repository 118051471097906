import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Password from "../../../components/Forms/Password";
import { apiRequest, formRequestToRaw } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { saveCredentials } from "../../../helpers/auth";
import { showMessage } from "../../../helpers/toast";
import { setGlobalAuthState, setGlobalState } from "../../../redux/dispatch";
import { objectValidate } from "../validator";

function Login() {
  /* Component state */
  const navigate = useNavigate();

  /* Form Ref */
  const loginForm = useRef();

  /* Handle api's request */
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData(loginForm.current);
    let rawReq = formRequestToRaw(formData);

    /* Validate user fields */
    let validateResponse = objectValidate(rawReq);
    if (!validateResponse.isValid) {
      return showMessage(validateResponse.msg);
    }
    setGlobalState({ loader: true });
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.login,
      method: "post",
      body: rawReq,
      token: false,
    });
    setGlobalState({ loader: false });

    if (success) {
      if (!data.is_email_verified) {
        showMessage("We send an OTP on your account");
        return navigate(
          "/twostep-verification/" +
            data.access_token +
            "/" +
            formData.get("email")
        );
      } else if (!data.security_ques_1) {
        return navigate("/security-question/1/" + data.access_token);
      } else if (!data.security_ques_2) {
        return navigate("/security-question/2/" + data.access_token);
      } else if (!data.security_ques_3) {
        return navigate("/security-question/3/" + data.access_token);
      }
      showMessage(message);
      saveCredentials({ token: data.access_token, ...data });
      setGlobalAuthState({ token: data.access_token, ...data });
      navigate("/");
    } else {
      showMessage(message);
    }
  };
  return (
    <>
      <section class="signup-bg login-bg">
        <div class="container">
          <div class="row py-4 py-sm-5">
            <div class="col-md-5 col-11 mb-4 mb-sm-0">
              <div class="signup-lefttextbox">
              <span className="back-boxarrow">
                <img
                  src="/images/trun-back.png"
                  alt="/"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ cursor: "pointer" }}
                />
              </span>
              
                <Link to="/">
                  <h1>Welcome To</h1>
                  <img
                    src="images/signup-logo.png"
                    className="signup-logo"
                    alt="/"
                  />
                </Link>
              </div>
            </div>
            <div class="col-md-7">
              <div className="row pt-sm-4 ">
                <div className="col-md-10">
                  <div class="signup-righttextbox">
                    <div class="row mt-sm-5 mt-0 justify-content-end">
                      <div class="col-md-8">
                        <form ref={loginForm} onSubmit={handleSubmit}>
                          <h3>Login </h3>
                          <div className="mb-sm-4 mb-3 mt-sm-5 mt-3">
                            <input
                              type="email"
                              name="email"
                              class="form-control input-box"
                              placeholder="Email"
                            />
                          </div>
                          <div className="pt-2 position-relative">
                            <Password name={"password"} />
                          </div>
                          <div class="text-right mt-3">
                            <h5 onClick={() => navigate("/ForgetPassword")}>
                              Forgot password ?
                            </h5>
                          </div>

                          <div className="mt-sm-5 mt-3">
                            <button type="submit" class="next-btn btn-block">
                              Login
                            </button>
                          </div>
                          <div class="row justify-content-center pt-sm-5 pt-4">
                            <div class="col-md-9 col-9">
                              <div class="d-flex justify-content-between align-items-center">
                                <h5>Don’t have Account ?</h5>
                                <button
                                  type="button"
                                  class="newlogin-btn"
                                  onClick={() => navigate("/Register")}
                                >
                                  Register
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
