
import axios from 'axios'
import { getCredentials } from '../helpers/auth';
import { SERVER_URL } from './constants';
console.log("SERVER_URL,,,,,,,,,",SERVER_URL);
export const formRequestToRaw = (formData, skipKeyList = []) => {
    let rawReq = {}
    for (const list of formData.entries()) {
        if (!skipKeyList.includes(list[0])) {
            rawReq[list[0]] = list[1]
        }
    }
    return rawReq
}

const getRequestHeaders = (type) => {
    let headers = {};
    switch (type) {
        case "json":
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            };
            break;
        case "form":
            headers = {
                'Accept': 'application/form-data',
                'Cache-Control': 'no-cache',
            };
            break;
        default:
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            };
    }
    return headers;
};

export const createQueryOrParams = (object, type = "query") => {
    // console.log(object);
    let string = "", length = Object.entries(object).length || 0
    if (type === "query") {
        string = "?"
        Object.entries(object).forEach((item, index) => {
            string = string + item[0] + "=" + item[1];
            if (length !== index + 1) {
                string = string + "&"
            }
        })
    } else if (type === "params") {
        string = "/"
        Object.entries(object).forEach((item, index) => {
            string = string + item[1];
            if (length !== index + 1) {
                string = string + "/"
            }
        })
    }
    return string;
}

export const apiRequest = async ({
    endUrl,
    method,
    headerType = "json",
    body = null,
    query = null,
    params = null,
    token = true,
    customToken = null
}) => {
    let requestHeaders = getRequestHeaders(headerType);
    if (headerType === "file") {
        requestHeaders["Content-Type"] = 'multipart/form-data;'
        requestHeaders["Accept"] = '*/*;'
       
    }
    if (token) { /* Add token */
        requestHeaders.Authorization = `Bearer ${customToken || getCredentials().token}`;
    }
    if (params) { /* Add params */
        endUrl = endUrl + createQueryOrParams(params, "params")
    }
    if (query) { /* Add query */
        endUrl = endUrl + createQueryOrParams(query, "query")
    }

    const options = {
        url: `${SERVER_URL}/${endUrl}`,
        method: method,
        headers: requestHeaders,
    };
    if(body){
        options.data = body
    }

    try {
        var errorTags = document.querySelectorAll('.help-block');
        errorTags.forEach(errorTag => {
        errorTag.remove();
        });

        let response = await axios(options);
        if (!response.data.error) {  /* Will modify according to api's response */
            response = { success: true, data: response?.data?.data || {}, message: response?.data?.msg || "" }
        } else {
            response = { success: false, data: {}, message: response.data.msg || "" }
        }
        return response;
    } catch (e) {
        if (e.response.status === 422) {
            let msg = ""
            let errors = ""
            let response = null
            if (Object.entries(e.response.data?.msg?.errors).length > 0) {
                errors = e.response.data?.msg?.errors
                Object.entries(e.response.data?.msg?.errors).some((list) => {
                    var msg = list[1][0]
                    var name = list[0]
                    response = { success: false, data: errors, message: msg }
                    return true
                    // var errorTag = '<span className="text-danger help-block">' + msg + '</span>';

                    // var element = document.querySelector("form [name=" + name + "]");
                    // if(element){
                    //     if (element.getAttribute('type') == 'radio' || element.getAttribute('type') == 'checkbox') {
                    //         element.parentNode.insertAdjacentHTML('afterend', errorTag);
                    //     }else{
                    //         element.insertAdjacentHTML('afterend', errorTag);
                    //     }
                    // }
                })
               
            }
            return response ||  { success: false, data: errors, message: "Invalid Request" }
        }
        else {
            return { success: false, data: {}, message: e?.response?.data?.msg }
        }
    }
};
