import React, { useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { showMessage } from "../frontend/toast";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { Tooltip } from "react-bootstrap";
import ToolTip from "../../components/Tooltip/ToolTip";
import { socket } from "../../config/socket";
import moment from "moment";

function Sparessheader() {
  const navigate = useNavigate();
  const params = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [shows, setShows] = useState(false);
  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const [NotificationShows, setNotificationShows] = useState(false);

  const handleNotificationCloses = () => setNotificationShows(false);
  const handleNotificationShows = () => setNotificationShows(true);

  const [allNotificationData, setAllNotificationData] = useState([]);
  const [allNotificationReadData, setAllNotificationReadData] = useState([]);
  const [allNotificationUnreadData, setAllNotificationUnreadData] = useState(
    []
  );
  const [notificationCount, setNotificationCount] = useState([]);
  const [notifucationUnreadCount, setNotificationUnreadCount] = useState([]);

  const [data, setData] = useState({});

  const [isActive, setIsActive] = useState(false);

  const toggleActiveState = () => {
    setIsActive(!isActive);
  };

  const logoutClick = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      localStorage.clear();
      navigate("/Login");
    }
  };

  const fetchUsers = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setData({ ...data });
    } else {
      showMessage(message);
    }
  };

  const notificationStatusUpdate = async (id) => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.notificationUpdateStatus + "/" + id,
        method: "put",
      });
      if (success) {
      } else {
        showMessage(message);
      }
    } catch (error) {
      console.log("🚀 ~ notificationStatusUpdate ~ error:", error);
    }
  };

  useEffect(() => {
    const fatchNotification = async () => {
      try {
        const { data, message, success } = await apiRequest({
          endUrl: API_END_URLS.fatchNotification,
          method: "get",
        });
        if (success) {
          setAllNotificationData(data?.notificationData);
          setAllNotificationReadData(data?.readNotificationData);
          setAllNotificationUnreadData(data?.unReadNotificationData);
          setNotificationCount(data?.notificationCount);
          setNotificationUnreadCount(data?.unreadNotificationCount);
        } else {
          showMessage(message);
        }
      } catch (error) {
        console.log("🚀 ~ fatchNotification ~ error:", error);
      }
    };
    fetchUsers();
    fatchNotification();
  }, [NotificationShows, notificationCount, notifucationUnreadCount]);

  useEffect(() => {
    if (data?.user?.id) {
      socket.on(`messageRecevied/${data?.user?.id}`, (response) => {});
      return () => {
        socket.off();
      };
    }
  }, [data]);
  return (
    <>
      <header class="d-none d-sm-block">
        <div class="container-fluid">
          <div class="tophead">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="search-head">
                  <input className="form-control" type="text" />
                  <img src="/images/serch-icon-head.svg" alt="/" />
                </div>
              </div>
              <div className="col-md-8">
                <ul className="list-unstyled tophead-right">
                  <li>
                    <Link to="/seller/excisting">
                      <img src="/images/exciting-head-icon.svg" alt="/" />
                    </Link>
                  </li>
                  <li>
                    <ToolTip />
                  </li>
                  <li>
                    <div className="login-button ">
                      <button className="btn btn-admin" onClick={handleShows}>
                        Subscribe
                      </button>
                    </div>
                  </li>
                  <li>
                    <Link to="/seller/setting/help-desk">
                      <img src="/images/setting-head-icon.svg" alt="/" />
                    </Link>
                  </li>
                  <li>
                    <div className="login-button">
                      <button
                        className={`btn btn-admin d-flex align-items-center ${
                          isActive ? "active" : "inactive"
                        }`}
                        onClick={toggleActiveState}
                      >
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isActive}
                            onChange={toggleActiveState}
                          />
                          <span className="slider round"></span>
                        </label>
                        <span className="slider-round-text">
                          {isActive ? "Active" : "Inactive"}
                        </span>
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="notify-bell position-relative">
                      <img
                        src="/images/bell.svg"
                        class="bell-img"
                        alt="/"
                        onClick={() => handleNotificationShows()}
                      />
                      <span
                        class="dots"
                        onClick={() => handleNotificationShows()}
                      >
                        {notifucationUnreadCount}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="user-profile d-flex align-items-center">
                      <p className="pr-3">{data?.user?.name}</p>
                      <Dropdown className="user-drop">
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                          <img src={data?.user?.profile_url} alt="" />
                          {/* {data?.user?.profile_url ? <img
                            src={
                              API_END_URLS.S3Bucket + data?.user?.profile_url
                            }
                            class="pro-headimg"
                            alt=""
                          /> : <img src="/images/userspic.png" alt="" />} */}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="user-drop">
                          <Dropdown.Item href="/seller/profile">
                            {" "}
                            <img src="/images/drop-user.png" alt="" /> View
                            Profile
                          </Dropdown.Item>
                          <Dropdown.Item href="/">
                            {" "}
                            <img src="/images/drop-logout.png" alt="" /> Go To
                            Website
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => logoutClick()}>
                            {" "}
                            <img
                              src="/images/drop-lock.png"
                              alt=""
                            /> Logout{" "}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header class="d-block d-sm-none">
        <div class="tophead">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-7 pr-0 pl-1 d-flex">
                <img
                  src="images/menu-left-alt.svg"
                  class="mr-1 menu-btn-left"
                  onClick={handleShow}
                  alt=""
                />
                <Link to="/">
                  <img src="images/black-logo.png" class="logo-mobile" alt="" />{" "}
                </Link>
              </div>
              <div class="col-5 pr-0">
                <ul class="tophead-right">
                  <li>
                    <Link to="/">
                      <img src="images/bell.png" class="bell-img" alt="" />{" "}
                      <span class="dots new-dots"></span>{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to="#">
                      <img
                        src="images/user-pic.png"
                        class="pro-headimg"
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal-dialog-slideout home-pop"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="images/logo.png" alt="" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row">
            <div class="col-md-12">
              <div class="home-popcontent">
                <ul>
                  <li>
                    <Link to="/">
                      {" "}
                      <i class="icon-new-dashbord"></i>Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      {" "}
                      <i class="icon-sparess-paid"></i> Paid Services{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      {" "}
                      <i class="icon-posting"></i> Posting{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      {" "}
                      <i class="icon-connect"></i> Connect{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      {" "}
                      <i class="icon-profile"></i> Profile{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      {" "}
                      <i class="icon-sparess-setting"></i> Setting{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal className="new-model" show={shows} onHide={handleCloses}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row text-center justify-content-center">
            <div className="col-md-10">
              <img src="/images/rocket.gif" className="rocket-gif" alt="" />

              <div className="pop-content">
                <h1 className="pt-0">Dear User</h1>
                <h2>
                  For more features and a batter user experience, Please Upgrade
                </h2>
              </div>
              <div className="border-bottom mt-4"></div>
              <button
                type="button"
                class="btn sparessfill-btn new-save mt-3 mb-3"
                onClick={() => navigate("/choose-plan")}
              >
                Subscribe Now
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="push-notification-pop"
        show={NotificationShows}
        onHide={handleNotificationCloses}
      >
        <Modal.Header>
          <div className="push-headtext">
            <h1>Notification</h1>
            <h5>
              <img src="/images/push-tick-img.svg" alt="/" /> Mark all as read
            </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="push-tabs-pop">
            <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
              <Tab
                eventKey="all"
                title={
                  <span>
                    All <span className="badge">{notificationCount}</span>
                  </span>
                }
              >
                {allNotificationData?.map((item) => {
                  return (
                    <div className="row mt-3">
                      <div className="col-md-2 col-3">
                        <div className="push-icon">
                          <img src="/images/push-icon1.png" alt="/" />
                        </div>
                      </div>
                      <div className="col-md-10 col-9 pl-0">
                        <div
                          className="push-context"
                          onClick={() => notificationStatusUpdate(item?.id)}
                        >
                          <h3>{item?.message}</h3>
                          <div className="d-flex align-items-center">
                            <p>{moment(item?.createdAt).fromNow()}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-12">
                        <div className="push-btm-border"></div>
                      </div>
                    </div>
                  );
                })}
              </Tab>
              <Tab eventKey="read" title="Read">
                {allNotificationReadData?.map((item, id) => {
                  return (
                    <div className="row mt-3">
                      <div className="col-md-2 col-3">
                        <div className="push-icon">
                          <img src="/images/push-icon1.png" alt="/" />
                        </div>
                      </div>
                      <div className="col-md-10 col-9 pl-0">
                        <div className="push-context">
                          <h3>{item?.message}</h3>
                          <div className="d-flex align-items-center">
                            <p>{moment(item?.createdAt).fromNow()}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-12">
                        <div className="push-btm-border"></div>
                      </div>
                    </div>
                  );
                })}
              </Tab>
              <Tab
                eventKey="unread"
                title={
                  <span>
                    Unread{" "}
                    <span className="badge">{notifucationUnreadCount}</span>
                  </span>
                }
              >
                {allNotificationUnreadData?.map((item, id) => {
                  return (
                    <div className="push-area">
                      <ul className="list-unstyled push-area-list">
                        <li className="unread-list">
                          <div className="row mt-3">
                            <div className="col-md-2 col-3">
                              <div className="push-icon">
                                <img src="/images/push-icon1.png" alt="/" />
                              </div>
                            </div>
                            <div className="col-md-10 col-9 pl-0">
                              <div className="push-context">
                                <h3>{item?.message}</h3>
                                <div className="d-flex align-items-center">
                                  <p>{moment(item?.createdAt).fromNow()}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Sparessheader;
