import React, { useEffect, useRef, useState } from "react";
import { socket } from "../../config/socket";
import { API_END_URLS } from "../../config/endUrls";
import moment from "moment";
import { Modal, Table } from "react-bootstrap";
import { apiRequest } from "../../config/apiRequest";
import { showMessage } from "../frontend/toast";
import { useNavigate, useParams } from "react-router-dom";

export default function Chat({
  roomID,
  senderId,
  receiverId,
  revevierName,
  revevierProfileImage,
  senderProfileImage,
  channelName,
  videoIcon = true,
  voiceIcon = true,
}) {
  const navigate = useNavigate();

  const [uploadImage, setUploadImage] = useState({
    imageRender: null,
    imageFile: null,
  });

  const [lgShow, setLgShow] = useState(false);
  const handleShow = () => setLgShow(true);
  const handleClose = () => setLgShow(false);

  const text = useRef();
  const messageDivRef = useRef(null);
  const [chatBackup, setChatBackup] = useState([]);

  const saveImage = (e) => {
    let type = ["image/gif", "image/jpeg", "image/png"];

    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 50 && fileSize < 2000) {
        alert("Please upload file size must be between 200KB and 2MB");
        return false;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        let nab = { ...uploadImage };
        nab["imageFile"] = file;
        nab["imageRender"] = e.target.result;
        setUploadImage(nab);
      };
      setLgShow(true);
      reader.readAsDataURL(e.target.files[0]);
    }

    return true;
  };

  const uplaodImage = async () => {
    const fileData = new FormData();
    let fileJson = {};
    fileData.append("file", uploadImage.imageFile);
    let fname = uploadImage.imageFile.name;
    fileJson[fname] = "chatImage/" + fname;
    fileData.append("jsondata", JSON.stringify(fileJson));
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.fileUpload,
      method: "post",
      body: fileData,
      headerType: "file",
    });
    if (success) {
      sendMessage("media", data);
      handleClose();
    } else {
      showMessage(message);
    }
  };
  useEffect(() => {
    messageDivRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatBackup]);

  useEffect(() => {
    console.log(`onMessage/${senderId}`);
    socket.on(`onMessage/${senderId}`, (response) => {
      console.log(`onMessage`, response.data);
      if (response.data) {
        setChatBackup((prevChatBackup) => [...prevChatBackup, response.data]);
      } else {
        console.error("Invalid response or response data:", response);
      }
      // if(response && response?.data){
      //   setChatBackup((prevChatBackup) => [...prevChatBackup, ...response?.data]);
      // }
      // setChatBackup([...chatBackup, response?.data]);
    });

    fetchOldMessage();
    return () => {
      socket.off();
    };
  }, [receiverId, socket]);

  const fetchOldMessage = () => {
    socket.emit(
      "oldMessage",
      {
        conversation_id: roomID,
        receive_by: receiverId,
      },
      (response) => {
        console.log("My Data", response);
        if (response && Array.isArray(response.data)) {
          setChatBackup((prevChatBackup) => [
            ...prevChatBackup,
            ...response.data,
          ]);
        } else {
          console.error("Invalid response or response data:", response);
        }
        // setChatBackup((prevChatBackup) => [...prevChatBackup, ...response?.data]);
      }
    );
  };

  const sendMessage = (type = "text", mediaURL) => {
    if (type === "text" && !text?.current?.value) {
      return alert("Please type here");
    }
    const socketPayload = {
      conversation_id: roomID,
      msg: mediaURL ? mediaURL : text?.current?.value,
      msg_type: type,
      send_by: senderId,
      receive_by: receiverId,
      unseen_count_type: 2,
    };
    socket.emit("sendMessage", socketPayload, (response) => {
      setChatBackup([...chatBackup, response]);
      text.current.value = "";
    });
  };

  const hendleVideoOn = () => {
    navigate("/seller/video/" + channelName);
  };

  const hendleVideoCallOn = () => {
    navigate("/seller/voice-call/" + channelName);
  };

  return (
    <React.Fragment>
      <div className="container-fluid pl-0">
        <div className="row">
          <div className="col-md-12">
            {/* Header */}
            <div className="row mt-3">
              <div className="col-md-8" ref={messageDivRef}>
                <div className="d-flex align-items-center">
                  <div className="middle-userpic">
                    {revevierProfileImage ? (
                      <img
                        src={API_END_URLS.S3Bucket + revevierProfileImage}
                        alt=""
                      />
                    ) : (
                      <img src="/images/userspic.png" alt="" />
                    )}
                    <span class="green-dots"></span>
                  </div>
                  <div className="connect-usercontent">
                    <h6>{revevierName}</h6>
                    {/* <p>Online</p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex justify-content-between">
                  {videoIcon && (
                    <img
                      src="/images/greenvideo.svg"
                      alt=""
                      onClick={() => hendleVideoOn()}
                    />
                  )}
                  {voiceIcon && (
                    <img
                      src="/images/greencall.svg"
                      alt=""
                      onClick={() => hendleVideoCallOn()}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* Main scrolling content */}
            <div className="new-chatscroll">
              <div className="main-box">
                <div className="row mt-2">
                  <div className="col-md-12 text-center"></div>
                </div>
                {chatBackup?.map((item) =>
                  item?.send_by === senderId ? (
                    //Recevie messages
                    <div className="row justify-content-end mt-4">
                      <div className="col-md-9">
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="connect-usercontent newconnect-usercontent ">
                            <h6>
                              <span className="mr-3">
                                {moment(item?.createdAt).format("HH:MM")}
                              </span>{" "}
                              You{" "}
                            </h6>
                            <div className="chat-boxcontent newchat-boxcontent">
                              {item?.msg_type === "media" ? (
                                <a href={item?.msg} download={item?.msg}>
                                  <img src={item?.msg} alt="" />
                                </a>
                              ) : (
                                <h5>{item?.msg}</h5>
                              )}
                            </div>
                          </div>
                          <div className="middle-userpic middle-picrigth">
                            {senderProfileImage ? (
                              <img
                                src={API_END_URLS.S3Bucket + senderProfileImage}
                                alt=""
                              />
                            ) : (
                              <img src="/images/userspic.png" alt="" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    //Send  message
                    <div className="row mt-4">
                      <div className="col-md-9">
                        <div className="d-flex align-items-center">
                          <div className="middle-userpic">
                            {revevierProfileImage ? (
                              <img
                                src={
                                  API_END_URLS.S3Bucket + revevierProfileImage
                                }
                                alt=""
                              />
                            ) : (
                              <img src="/images/userspic.png" alt="" />
                            )}
                          </div>
                          <div className="connect-usercontent">
                            <h6>
                              {revevierName}{" "}
                              <span className="ml-3">
                                {moment(item?.createdAt).format("HH:MM")}
                              </span>
                            </h6>
                            <div className="chat-boxcontent">
                              {item?.msg_type === "media" ? (
                                <a href={item?.msg} download={item?.msg}>
                                  <img src={item?.msg} alt="" />
                                </a>
                              ) : (
                                <h5>{item?.msg}</h5>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            Chat send
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            >
              <div class="form-group position-relative">
                <input
                  type="text"
                  ref={text}
                  placeholder="Type..."
                  class="form-control connect-inpt"
                />
                <span className="connect-sendbg right-sendconnect">
                  <img
                    src="/images/greensend.svg"
                    alt=""
                    onClick={() => sendMessage("text")}
                  />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        className="new-model"
        show={lgShow}
        size="lg"
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div style={{ border: "2px solid red", color: "red" }}>
            <div className="upload-connnectimg">
              <img src={uploadImage["imageRender"]} alt="" />
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-md-10">
                <div class="form-group position-relative">
                  <input
                    type="text"
                    ref={text}
                    placeholder="Type..."
                    class="form-control connect-inpt"
                  />
                  <span className="connect-sendbg right-sendconnect new-rightconnect ">
                    <img
                      src="/images/greensend.svg"
                      alt=""
                      onClick={uplaodImage}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
