import React, { useEffect, useState } from "react";
import FooterBottam from "../../../components/FooterBottam/FooterBottam";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../include/header";
import { Dropdown } from "react-bootstrap";
import { API_END_URLS } from "../../../config/endUrls";
import { apiRequest } from "../../../config/apiRequest";
import { setGlobalState } from "../../../redux/dispatch";
import { showMessage } from "../toast";
import { S3Bucket, _subCategory } from "../../../config/constants";

function DealsProducts() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  const [filter, setFilter] = useState({
    category: id,
    subCategory: "",
    search: "",
  });

  const getCategories = async (req, res) => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.allCategories,
        method: "get",
        token: true,
      });
      setGlobalState({ loader: true });
      if (success) {
        setCategories(data);
      } else {
        showMessage(message);
        setGlobalState({ loader: false });
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: AllProductListing.jsx:116 ~ fatchAllCategories ~ err:",
        err
      );
    }
    setGlobalState({ loader: false });
  };

  const getAllProductList = async () => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getDealsPromotedProduct,
        method: "get",
        params: { id: filter?.category },
        query: { sub_category: filter?.subCategory, search: filter?.search },
      });
      setGlobalState({ loader: true });
      if (success) {
        setAllProducts(data);
      } else {
        showMessage(message);
        setGlobalState({ loader: false });
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: AllProductListing.jsx:140 ~ getAllProductList ~ err:",
        err
      );
    }
    setGlobalState({ loader: false });
  };

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname,
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllProductList();
  }, [filter]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    SeoCall();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <Header />
      <section className="pt-sm-5 pt-4">
        <div className="container">
          <div className="row mt-sm-3 align-items-center">
            <div className="col-md-2 col-2">
              <span className="back-boxarrow">
                <img
                  src="/images/trun-back.png"
                  alt="/"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
            <div className="col-md-9 col-10">
              <div className="top-headabout text-center">
                <h2 className="goldman">All Products</h2>
              </div>

              <div className="top-headabout">
                <h6>{allProducts[0]?.Category?.description}</h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <div class="form-group position-relative">
                <img
                  src="/images/graysearch-icon.svg"
                  className="search-icon"
                  alt=""
                />
                <input
                  type="text"
                  class="form-control product-input"
                  placeholder="Search for Product Id, catagory or something..."
                  value={filter?.search}
                  onChange={(e) => {
                    setFilter({ ...filter, search: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-md-2 pl-sm-0">
              <div className="d-flex align-items-center">
                <button type="button" class="btn sconnect-btn mr-2">
                  Search
                </button>
                {localStorage.getItem("removeFilter") !== "true" && (
                  <Dropdown className="filter-drop">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src="/images/filter.svg" alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <div>
                          <p>Multiple Select</p>
                        </div>
                        <div>
                          <h6>CLEAR</h6>
                        </div>
                      </div>
                      <div class="desgin-radio mb-2">
                        <input
                          class="with-gap"
                          type="radio"
                          name="filter"
                          id="client100"
                        />
                        <label for="client100" className="category-label">
                          All
                        </label>
                      </div>
                      <div class="desgin-radio">
                        <input
                          class="with-gap"
                          type="radio"
                          name="filter"
                          id="client101"
                        />
                        <label for="client101" className="category-label">
                          Promoted
                        </label>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-sm-4 mt-3">
            <div className="col-md-4 mb-sm-0 mb-3">
              <label className="category-mainlabel">Category</label>
              <div className="category-box">
                <div className="d-flex justify-content-between">
                  {categories?.map((item, index) => {
                    return (
                      <div class="desgin-radio">
                        <input
                          class="with-gap"
                          type="radio"
                          name="client-selector1"
                          checked={item?.id == filter.category}
                          id={`category${item?.id}`}
                          value={item?.id}
                          onChange={(e) =>
                            setFilter({ ...filter, category: item?.id })
                          }
                        />
                        <label
                          for={`category${item?.id}`}
                          className="category-label"
                        >
                          {item?.category_name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <label className="category-mainlabel">Sub - Category</label>
              <div className="category-box">
                <div className="d-sm-flex d-block justify-content-between">
                  {_subCategory.map((item, index) => {
                    return (
                      <div class="desgin-radio">
                        <input
                          class="with-gap"
                          type="radio"
                          name="client-selector2"
                          id={`subCategory_${item}`}
                          checked={item == filter.subCategory}
                          onChange={(e) =>
                            setFilter({ ...filter, subCategory: item })
                          }
                        />
                        <label
                          for={`subCategory_${item}`}
                          className="category-label"
                        >
                          {item}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="product-pricelabel d-sm-block d-none mt-sm-5 mt-3">
            <div className="row mt-sm-4 mt-3 pb-2">
              <div className="col-md-4">
                <p>Product</p>
              </div>
              <div className="col-md-2 text-center">
                <p>Category</p>
              </div>
              <div className="col-md-2 text-center">
                <p>Price</p>
              </div>
              <div className="col-md-2 text-center">
                <p>Available</p>
              </div>
              <div className="col-md-2">
                <p>  Action  </p>
              </div>
            </div>
          </div>

          {allProducts?.map((item) => {
            return (
              <div className="product-detailsbox mt-4">
                <div className="row">
                  <div className="col-md-4 mb-sm-0 mb-3">
                    <div className="row align-items-center">
                      <div className="col-md-5 mb-sm-0 mb-3">
                        <div className="product-detailsimgbox">
                          <Link to={`/range-products/${item?.id}`}>
                            {item?.ProductImages[0] ? (
                              <div className="table-firstimg">
                                <img
                                  src={
                                    S3Bucket + item?.ProductImages[0]?.image_url
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              "No Photo"
                            )}
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-7">
                        {item?.product_promotion === 1 && (
                          <span className="promoted-product">
                            <img
                              src="/images/reward.svg"
                              className="reward-img"
                              alt="/"
                            />
                            Promoted Product
                          </span>
                        )}

                        <Link to={`/range-products/${item?.id}`}>
                          <h4>
                            {item?.CategoryProduct?.product_name} <br />{" "}
                            {item?.brand}
                          </h4>
                          <h6>{item?.description}.</h6>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-4">
                    <h3>{item?.Category?.category_name}</h3>
                  </div>
                  <div className="col-md-2 col-4">
                    <h3>{item?.amount}</h3>
                  </div>
                  <div className="col-md-2 col-4">
                    <h3>{item?.qty}</h3>
                  </div>
                  <div className="col-md-2 mt-3 mt-sm-0">
                    <button
                      type="button"
                      class="btn contact-btn btn-block mt-sm-3"
                    >
                      Connect
                    </button>
                    <div className="row mt-3">
                      <div className="col-md-6 col-6 pr-sm-2">
                        <button
                          type="button"
                          class="btn contact-btn px-2  btn-block "
                        >
                          <img src="/images/chat-green.svg" alt="/" />
                        </button>
                      </div>
                      <div className="col-md-6 col-6 pl-sm-2">
                        <button
                          type="button"
                          class="btn contact-btn px-2 btn-block "
                        >
                          <img src="/images/call-filled.svg" alt="/" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <FooterBottam></FooterBottam>
    </React.Fragment>
  );
}

export default DealsProducts;
