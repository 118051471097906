import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal, Table } from 'react-bootstrap';
import { apiRequest } from '../../config/apiRequest';
import { API_END_URLS } from '../../config/endUrls';
import { showMessage } from '../../helpers/toast';
import { setGlobalState } from '../../redux/dispatch';


function ChooseBasicPlan() {
    let index = 0;
    const [plan, setPlan] = useState([])
    const [shows, setShows] = useState(false);
    const navigate = useNavigate()

    const handleShows = () => setShows(true);
    const handleCloses = () => setShows(false);
    const [lgShow, setLgShow] = useState(false);

    const { type } = useParams()

    const [subScriptionAcceptance, setSubSubcriptionAcceptance] = useState({});

    const fetchPlan = async () => {
        const { data, message, success } = await apiRequest({ endUrl: API_END_URLS.getChooseSubscriptionPlan, method: 'get', query: { type } });
        console.log("🚀 ~ file: ChooseBasicPlan.jsx:22 ~ fetchPlan ~ data:", data)
        if (success) {
            setPlan(data[type])
        } else {
            showMessage(message)
        }
    }

    const subscriptionAcceptance = async () => {
        const { data, message, success } = await apiRequest({ endUrl: API_END_URLS.getSubscriptionAcceptance, method: 'get' });
        if (success) {
            setSubSubcriptionAcceptance({ ...data })
        } else {
            showMessage(message)
        }

    }
    const submitPlan = async (item) => {
        setGlobalState({ loader: true })
        let req = {
            "planId": item.id,
            "amount": item.plan_cost,
            "currency": item.currency
        }
        const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.subscriptionPlanSubmit, method: 'POST', body: req })
        console.log("🚀 ~ file: ChooseBasicPlan.jsx:53 ~ submitPlan ~ data:", data)
        setGlobalState({ loader: false })
        if (success) {
            navigate("/checkout?secret=" + data?.clientSecret)
        } else {
            showMessage(message)
        }
    }

    useEffect(() => {
        fetchPlan();
        subscriptionAcceptance()
    }, [])

    return (

        <>
            <section class="py-4   plan-section ">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="buy-formtext">
                                        <h1>Choose Your Plan</h1>
                                        <div class="heading-seperator heading-seperator2"><span></span></div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-left text-sm-right">
                                    <img src="images/new-logoplan.png" alt="" />
                                </div>
                            </div>

                            <div class="row mt-3 pb-5 justify-content-center">
                                {
                                    plan.map((item, index) => <div class="col-md-6 mb-2 mb-sm-0">
                                        <div class="planprice-box subscription-plan-box">

                                            <div class="d-flex justify-content-between px-3 align-items-center">
                                                <div class="subs-plan-head">
                                                    <h1> <img src="/images/plan-top.png" class="mr-2" alt="" /> {type == "standard" ? "Standard" : "Premium"} Plan</h1>
                                                    <div class="heading-seperator heading-seperator4"><span></span></div>
                                                </div>
                                                <button onClick={handleShows} type="button" class="btn  basic-btn pop-btn">*Subscription Acceptance Plan</button>
                                            </div>
                                            <hr />

                                            <div class="planprice-boxcontent">
                                                <div class="row justify-content-center">
                                                    <div class="col-md-10">
                                                        <h6 class="py-2">Plan Includes in your 30 Days Free Trial :</h6>
                                                        <div class="free-trial">
                                                            <p>1 - Period</p>
                                                            <h6>{item.period}</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <p>2 - No of Days</p>
                                                            <h6>{item.days}</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <p>3 - Plan Cost</p>
                                                            <h6>{item.plan_cost}</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <p>3 - Currency</p>
                                                            <h6>{item.currency}</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <p>4 - No. of Posting allowed</p>
                                                            <h6>{item.number_of_free_post}</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <p>5 - No. of notification Allowed Per Week</p>
                                                            <h6>{item.push_notifications_allowed}</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <p>6 - No of Photos Allowed per Item</p>
                                                            <h6>2</h6>
                                                        </div>
                                                        <div className="free-trial">
                                                            <p>7 - Get Connected with all Std. Users(To Buy & Sell)</p>
                                                            <h6>---</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <p>8 - Easy Connectivity via Audio & Messaging</p>
                                                            <h6>---</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <p>9 - Additional Benefits</p>
                                                            <h6>----</h6>
                                                        </div>
                                                        <div class="free-trial">
                                                            <ul>
                                                                <li>
                                                                    <p>Get Access to Product Posting at Minimal Cost (Even After End of the plan)</p>
                                                                </li>
                                                            </ul>
                                                            <h6>----</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="text-center pb-4">

                                                    <button
                                                        type="button"
                                                        class="btn  basic-btn "
                                                        onClick={() => submitPlan(item)}
                                                    >
                                                        Activate Your Plan

                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>


                </div>

            </section>

            <Modal show={shows} onHide={handleCloses} className="overview-btn-pop">
                <Modal.Header>
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="text-right upload-pop-icon">
                                <img src="/images/upload-pop-icon.png" onClick={() => setLgShow(true)} alt="/" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                className=" scroll-pop "
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton >
                    {/* <img src="images/buy-cross.png" class="cross-img"> */}
                    {/* <img src="images/buy-cross.png"  alt="/" /> */}
                    <h1 className="new-pophead">Subscription Acceptance Page </h1>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="overscroll-datatable pr-1">
                        <div className="table-responsive" >
                            <Table bordered className="new-tableyes" >
                                <tbody>
                                    <tr>
                                        <th className="text-center" >Sl. No</th>
                                        <th className="text-center" >Description</th>
                                        <th className="text-center" colSpan={2} >Standard</th>
                                        <th className="text-center" colSpan={2} >Premium</th>
                                    </tr>
                                    {
                                        Object.entries(subScriptionAcceptance).map((list) => {
                                            if (!["id"].includes(list[0])) {
                                                index = index + 1;
                                                return <tr>
                                                    <td>{index}</td>
                                                    <td>{list[1][0]?.description}</td>
                                                    <td className="text-center">{list[1][0]?.standard_1}</td>
                                                    <td className="text-center">{list[1][0]?.standard_2}</td>
                                                    <td className="text-center">{list[1][0]?.premium_one}</td>
                                                    <td className="text-center">{list[1][0]?.premium_2}</td>
                                                </tr>
                                            } else {
                                                <></>
                                            }
                                        })
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


        </>
    );
}

export default ChooseBasicPlan;