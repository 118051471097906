import React, { useState } from 'react';
import Sparessheader from '../../include/sparess-header';
import SparessSidemenu from '../../include/sparess-sidemanu';
import { Link, Navigate } from "react-router-dom";
import Password from '../../../components/Forms/Password';
import { apiRequest } from '../../../config/apiRequest';
import { API_END_URLS } from '../../../config/endUrls';
import { showMessage } from '../../frontend/toast';


function PasswordChange() {

    const [pass, setPass] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    })

    const handleSubmit = async () => {
        let req = {
            oldPassword: pass.oldPassword ? pass.oldPassword?.trim() : null,
            newPassword: pass.newPassword ? pass.newPassword?.trim() : null,
            confirmPassword: pass.confirmPassword ? pass.confirmPassword?.trim() : null,
        }
        if (!req.oldPassword) {
            showMessage("Please enter old password")
            return
        } else if (!req.newPassword) {
            showMessage("Please enter  new password")
            return
        } else if (!req.confirmPassword) {
            showMessage("Please enter confirm password")
            return
        }
        if (req.oldPassword === req.newPassword) {
            showMessage("Please Enter New Password")
            return
        }
        else if (req.newPassword !== req.confirmPassword) {
            showMessage("Please Enter Correct New Confirm Password")
            return
        }
        const { data, message, success } = await apiRequest({ endUrl: API_END_URLS.changePassword, method: "put", body: req })
        if (success) {
            showMessage(message)
            setPass({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
            })
        } else {
            showMessage(message)
        }

    }

    return (
        <>
            <div class="container-fluid" id='seller-panel'>
                <div class="row">
                    <div class="col-md-3 pr-0 d-none d-sm-block">
                        <SparessSidemenu></SparessSidemenu>
                    </div>
                    <div class="col-md-9 p-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Sparessheader></Sparessheader>
                            </div>

                            <div class="pageinnner-padding">
                                <div class="row">
                                    {/* <div class="col-md-3 mb-2 mb-sm-0">
                                        <div class="content-menubox">
                                            <ul>
                                                <li><Link to="/seller/setting/services"> Services</Link></li>
                                                <li><Link to="/seller/setting/help-desk"> Help Desk </Link></li>
                                                <li className="active"><Link to="/seller/setting/change-password"> Change Password</Link></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    <div class="col-md-12">
                                        <div class="settingpadding-left allover-padding">
                                            <h1>Password Reset</h1>

                                            <div className='d-flex justify-content-end mt-3'>
                                                <Link to="/seller/setting/help-desk">
                                                    <button className='btn sparessfill-btn d-flex align-items-center'>
                                                        <span className='pass-group-bg mr-2'>
                                                            <img src='/images/lock-pass-icon.svg' alt='/'></img>
                                                        </span>
                                                        Help Desk
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className='border-bottom my-4'></div>
                                            <div className='row justify-content-center'>
                                                <div className='col-md-11'>
                                                    <div class="desk-from">
                                                        <div class="desk-formbg desk-formbg-2">
                                                            <div className="row">
                                                                <div className='col-md-12'>
                                                                    <div class="form-contactsetting">
                                                                        <div className="row justify-content-center">
                                                                            <div className="col-md-6">
                                                                                <div class="form-group">
                                                                                    <label class="edit-label" > Old Password </label>
                                                                                    <Password
                                                                                        placeholder={"Old Password"}
                                                                                        value={pass.oldPassword}
                                                                                        onChange={(value) => { setPass({ ...pass, oldPassword: value }) }}
                                                                                    />
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <label class="edit-label" > New Password </label>
                                                                                    <Password
                                                                                        placeholder={"New Password"}
                                                                                        value={pass.newPassword}
                                                                                        onChange={(value) => { setPass({ ...pass, newPassword: value }) }}

                                                                                    />
                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <label class="edit-label" > Confirm New Password </label>
                                                                                    <Password
                                                                                        placeholder={"Confirm Password"}
                                                                                        value={pass.confirmPassword}
                                                                                        onChange={(value) => { setPass({ ...pass, confirmPassword: value }) }}
                                                                                    />
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                        <div class="row justify-content-center">
                                                                            <div className='col-md-3'>

                                                                                <button
                                                                                    type="button"
                                                                                    class="btn sparessfill-btn btn-block"
                                                                                    onClick={handleSubmit}
                                                                                >
                                                                                    Save
                                                                                </button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default PasswordChange;