import React from 'react';
import { Link } from "react-router-dom";

function Footer() {

    return (
        <>
            <section class="foot-section py-4 py-sm-5">
                <div class="container">

                    <div class="row mt-2">
                        <div class="col-md-4 col-6 order-sm-1 order-1 mb-sm-0 mb-3">
                            <div class="foot-righttext">
                                <h2>Links</h2>
                                <ul>
                                    <li><Link to="/contact-us">Connect With Us</Link></li>
                                    <li><Link to="/Advertisement-rule">Advertise With Us</Link></li>
                                    <li><Link to="/terms-conditions">Terms Of use</Link></li>
                                    <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                    {/* <li><Link to="/">Tech Support</Link></li> */}
                                    <li><Link to="/seller/cookie-policy">Cookie Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 text-center order-sm-2 order-3 mb-4 mb-sm-0">
                            <img src="images/foot-logo.svg" alt="" className="foot-logo" />
                            <div class="foot-contnet text-center mt-sm-5 mt-3">
                                <Link to="https://www.facebook.com/profile.php?id=61554268615686&mibextid=LQQJ4d" target="_blank"><img src="/images/facebook-green.svg" className="mr-sm-3 mr-2" alt="" /></Link>
                                <Link to="https://www.linkedin.com/company/navigators-are-us-fz-llc/" target="_blank"><img src="/images/linked.svg" className="mr-sm-3 mr-2" alt="" /></Link>
                                <Link to="https://x.com/navigatorsareus?s=11" target="_blank" ><img src="/images/twitter.svg" className="mr-sm-3 mr-2" alt="" /></Link>
                                <Link to="https://youtu.be/24k6xiDBF9s?si=ABP8KnwvsDA65abT" target="_blank"><img src="/images/youtube.svg" className="mr-sm-3 mr-2" alt="" /></Link>
                                <Link to="https://www.instagram.com/navigatorsareusfzllc?igsh=MXBvOTNhZTI1a3ZyYQ==" target="_blank"><img src="/images/insta.svg" alt="" /></Link>

                                <div class="mt-2 mt-sm-4">
                                    <div className='payment-links-icon'>
                                        <Link to="/"><img src="/images/payment-icon-1.png" alt="" /></Link>
                                        <Link to="/"><img src="/images/payment-icon-2.png" alt="" /></Link>
                                        <Link to="/"><img src="/images/payment-icon-3.png" alt="" /></Link>
                                        <Link to="/"><img src="/images/payment-icon-4.png" alt="" /></Link>
                                        <Link to="/"><img src="/images/payment-icon-6.png" alt="" /></Link>
                                        <Link to="/"><img src="/images/payment-icon-5.png" alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-6 order-sm-3 order-2 col-6 mb-sm-0 mb-3">
                            <div className="row justify-content-end">
                                <div className="col-md-4">
                                    <div class="foot-righttext">
                                        <h2>Location</h2>
                                        <ul>
                                            <li> <Link to="/">Dubai</Link></li>
                                            <li> <Link to="/">Abu Dhabi</Link></li>
                                            <li> <Link to="/">Sharjah</Link> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="copy-righttext text-center"> NavigatorsRus 2024 All Right Reserved</p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}


export default Footer;