import React from 'react';
// import { Container } from 'react-bootstrap';
// import { Link } from "react-router-dom";


// import Sonnet from '../../components/Sonnet';

function Question () {

    return (
        <>
            <section className="py-4 py-sm-5 register-bg login-sec">
                <div className="container">

                <div className="row justify-content-center ">
                    <div className="col-md-11">

                    <div className="row align-items-center my-4 my-sm-5 ">
                        <div className="col-md-5">
                            <div className="register-lefttextbox">

                                <img src="/images/left-bluearrow.png" alt='/' />
                                <h1>Welcome To </h1>
                                <img src="/images/login-logo.png" className="login-logonew" alt='/' />
                                <p>An Online Platform to Buy & Sell <br /> Marine and Offshore Equipment, <br /> Products, Parts and Services</p>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="row justify-content-center">
                                <div className="col-md-7">
                                    <div className="register-righttextbox">
                                        <h3 className="mb-3 mb-sm-4">Security Question</h3>

                                        <div className="form-group pt-4 pt-sm-5">
                                            <input type="email" className="form-control input-box" placeholder="Your Pet Name" />
                                        </div>
                                        <div className="text-center mt-4">
                                            <button type="button" className=" next-btn">Register</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    </div>
                </div>

                  

                </div>
            </section>



        </>
    );
}

export default Question;