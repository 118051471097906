import React, { useEffect, useState } from 'react';
import { format } from 'date-fns'
// import Sparessheader from '../../../include/sparess-header';
// import SparessSidemenu from '../../../include/sparess-sidemanu';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import { Modal } from 'react-bootstrap';
import { apiRequest } from '../../../../config/apiRequest';
import { API_END_URLS } from '../../../../config/endUrls';
import { showMessage } from '../../../../helpers/toast';
import { Link, useParams } from 'react-router-dom';
import { Pagination } from '@mui/material';
import { S3Bucket } from '../../../../config/constants';



function SellerServiceListing() {

    const [services, setServices] = useState([]);
    console.log(services);
    const [noOfPages, setNoOfPages] = useState(0);

    const [query, setQuery] = useState({
        product_type: 1,

        page: 1,
         limit: 10,
    });

    useEffect(() => {
        const getServiceListing = async () => {
            const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.getServices, method: "get", token: true, query: query  })
            setServices(data.services.rows);

            setNoOfPages(Math.ceil(data.services.count / query.limit));
        }
        getServiceListing();
    }, [query]);

    return (
        <>
            <div className="container-fluid" id="seller-panel">
                <div className="row">
                    <div className="col-md-3 pl-0 d-none d-sm-block">
                        {/* <SparessSidemenu></SparessSidemenu> */}
                    </div>
                    <div className="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div className="main-box">
                            <div className="page-headpaddingbox">
                                {/* <Sparessheader></Sparessheader> */}
                            </div>
                            <div className="pageinnner-padding">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="new-posttingtab" >
                                            <ul>
                                                <li ><Link to="/seller/posting/product/add">Add Products</Link></li>
                                                <li><Link to="/seller/posting/service/add">Add Services</Link></li>
                                                <li className="active"><Link to="/seller/posting/services">Posting</Link></li>
                                                <li><Link to="#">Promotions</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row mt-2">
                                            <div className="col-md-12">
                                                <div className="new-seller">
                                                    <ul>
                                                        <li>
                                                            <Link to="/seller/posting/products">
                                                                <button type="button" className="btn">Products</button>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/seller/posting/services">
                                                                <button type="button" className="btn active">Services</button>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                                    <div className="col-md-3 mb-2 mb-sm-0 ">
                                                        <div className="remaining-box" >
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <h1>25</h1>
                                                                    <h6>Subscribed</h6>
                                                                </div>
                                                                <div>
                                                                    <h1>05</h1>
                                                                    <h6>Paid</h6>
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <h5>Uploaded  Post</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-2 mb-sm-0">
                                                        <div className="remaining-box" >
                                                            <div className="row align-items-center">
                                                                <div className="col-md-6 col-6">
                                                                    <img src="/images/seller/box.svg" />
                                                                </div>
                                                                <div className="col-md-6 col-6 text-center">
                                                                    <h3>30</h3>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <h5>Total Post</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-2 mb-sm-0">
                                                        <div className="remaining-box" >
                                                            <div className="row align-items-center">
                                                                <div className="col-md-6 col-6">
                                                                    <img src="/images/seller/package.svg" />
                                                                </div>
                                                                <div className="col-md-6 col-6 text-center">
                                                                    <h3>30</h3>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <h5>Posted</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-2 mb-sm-0">
                                                        <div className="remaining-box" >
                                                            <div className="row align-items-center">
                                                                <div className="col-md-6 col-6">
                                                                    <img src="/images/seller/invoice.svg" />
                                                                </div>
                                                                <div className="col-md-6 col-6 text-center">
                                                                    <h3>00</h3>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <h5>Remaining</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <Table className="posting-table" >
                                                        <thead>
                                                            <tr>
                                                                <th>Service</th>
                                                                <th>Price</th>
                                                                <th>Category</th>
                                                                <th>Service Type</th>
                                                                <th>Status</th>
                                                                <th>Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {services.map((item,index) => {
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className="table-imgbox">
                                                                                    {
                                                                                        item?.ProductImages[0] ?
                                                                                            <div className="table-firstimg" ><img src={S3Bucket+item?.ProductImages[0]?.image_url} /></div>
                                                                                            : ''
                                                                                    }
                                                                                    <div><p> {item.title}  <br />   {item.brand}</p></div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{item.amount}</td>
                                                                            <td>{item?.Category?.name}</td>
                                                                            <td>{item?.type}</td>
                                                                            <td>{item?.status}</td>
                                                                            <td>{format(new Date(item.createdAt), "dd/MM/yyyy")}</td>
                                                                            <td><Link to={'/seller/posting/service/' + item.id}><button type="button" className="btn update-btn">Update</button></Link></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="row pt-4  pt-sm-5 justify-content-end">
                                                    <div className="text-right mr-4">
                                                        {
                                                            noOfPages ?
                                                                <Pagination count={noOfPages} defaultPage={1} siblingCount={2} boundaryCount={2} variant="outlined" shape="rounded" color="primary" onChange={(e, page) => {
                                                                    setQuery({ ...query, page: page });
                                                                }} />
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SellerServiceListing;