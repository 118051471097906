import React, { useEffect, useState } from "react";
import Footer from "./include/footer";
import Header from "./include/header";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../config/apiRequest";
import { API_END_URLS } from "../config/endUrls";
// import { setDatasets } from "react-chartjs-2/dist/utils";
import { showMessage } from "../helpers/toast";
import { _WhatWeOffer, _whoWeArePage } from "../config/constants";
import FooterBottam from "../components/FooterBottam/FooterBottam";
import { Helmet } from "react-helmet";

function WhatWeOffer() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getDataWhatWeAre,
      method: "get",
    });
    if (success) {
      setData(data);
    } else {
      showMessage(message);
    }
  };

  const getData = (type) => {
    let whatWeOffer = data?.filter((item) => {
      return item?.key === type;
    });
    console.log(whatWeOffer);
    if (whatWeOffer?.length) {
      console.log(
        "🚀 ~ file: WhatWeOffer.js:35 ~ getData ~ whatWeOffer?.length:",
        whatWeOffer?.length
      );
      return whatWeOffer[0].value;
    } else {
      return "";
    }
  };

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname,
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    SeoCall();
    fatchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <Header />
      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row mt-sm-3 align-items-center">
            <div className="col-md-2 col-2 text-center">
              <span className="back-boxarrow">
                <img
                  src="/images/trun-back.png"
                  alt="/"
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
            <div className="col-md-8 col-9">
              <div className="top-headabout text-center">
                <h2 className="goldman">What We Offer</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="offer-topsection mt-sm-5 mt-3">
            <div className="row align-items-center">
              <div className="col-md-6 mb-3 mb-sm-0">
                <img
                  src={getData(_WhatWeOffer.bannerImageOneUrl)}
                  className="offer-leftimg"
                  alt="/"
                />
              </div>
              <div className="col-md-6">
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-10">
                        <div className="offer-content">
                          <h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: getData(
                                  _WhatWeOffer.sectionHeadingDisplayProductOrService
                                ),
                              }}
                            />
                          </h2>
                          <p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: getData(
                                  _WhatWeOffer.sectionHeadingDisplayProductOrServiceText
                                ),
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 mb-sm-0 mb-4">
              <div className="photos-content">
                <h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(
                        _WhatWeOffer.sectionHeadingPhotosAndPicturesOfTheProduct
                      ),
                    }}
                  />
                </h2>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(
                        _WhatWeOffer.sectionHeadingPhotosAndPicturesOfTheProductText
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
            <div className="col-md-5 mt-2 mt-sm-0">
              <div className="product-imgbg">
                <img
                  src={getData(_WhatWeOffer.bannerImageTwoUrl)}
                  className="product-img"
                  alt="/"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-md-3 mb-sm-0 mb-3">
              <div className="offer-boximg1">
                <img src={getData(_WhatWeOffer.bannerImageFourUrl)} alt="/" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row align-items-center">
                <div className="col-md-4 mb-sm-0 mb-3">
                  <div className="offer-boximg1">
                    <img
                      src={getData(_WhatWeOffer.bannerImageThreeUrl)}
                      alt="/"
                    />
                  </div>
                </div>
                <div className="col-md-6 pl-sm-5">
                  <div className="advertisements-text">
                    <h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData(
                            _WhatWeOffer.sectionHeadingAdvertisementsBannersOrVideos
                          ),
                        }}
                      />
                    </h4>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData(
                            _WhatWeOffer.sectionHeadingAdvertisementsBannersOrVideosText
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mt-sm-5 mt-2">
                <div className="col-md-9">
                  <div className="advertisements-text">
                    <h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData(
                            _WhatWeOffer.sectionHeadingPromotionNotifications
                          ),
                        }}
                      />
                    </h4>
                    <p className="pt-sm-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData(
                            _WhatWeOffer.sectionHeadingPromotionNotificationsText
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 pb-4 pb-sm-0 ">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-sm-0 mb-3">
              <div className="voice-content">
                <div className="voice-imgbox">
                  <img src="images/vedio1.png" alt="/" />
                </div>
                <h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(
                        _WhatWeOffer.sectionHeadingVoiceTextMessaging
                      ),
                    }}
                  />
                </h5>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(
                        _WhatWeOffer.sectionHeadingVoiceTextMessagingText
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-sm-0 mb-3">
              <div className="voice-content">
                <div className="voice-imgbox">
                  <img src="images/vedio2.png" alt="/" />
                </div>
                <h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(_WhatWeOffer.sectionHeadingVideoChatting),
                    }}
                  />
                </h5>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(
                        _WhatWeOffer.sectionHeadingVideoChattingText
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="voice-content">
                <div className="voice-imgbox">
                  <img src="images/vedio3.png" alt="/" />
                </div>
                <h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(
                        _WhatWeOffer.sectionHeadingPersonalizedDashboard
                      ),
                    }}
                  />
                </h5>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(
                        _WhatWeOffer.sectionHeadingPersonalizedDashboardText
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterBottam></FooterBottam>

      <Footer></Footer>
    </>
  );
}

export default WhatWeOffer;
