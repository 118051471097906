import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Header from "../include/header";
import { apiRequest } from "../../config/apiRequest";
import { S3Bucket } from "../../config/constants";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import Footer from "../include/footer";
import { _aboutUsPage } from "../../config/constants";
import { Helmet } from "react-helmet";

function AboutsUs() {
  const [data, setData] = useState([]);

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter((module) => module.module === "about_us");
      setData(filter);
    } else {
      showMessage(message);
    }
  };

  const getData = (type) => {
    let homePageData = data?.filter((item) => item?.key === type);
    if (homePageData?.length) {
      return homePageData[0].value;
    } else {
      return "";
    }
  };

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    SeoCall();
    fatchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <Header></Header>

      <section
        className="about-section"
        style={{
          backgroundImage: `url(${getData(
            _aboutUsPage.aboutUsBannerImageUrl
          )})`,
        }}
      >
        <div className="about-bannertext">
          <h4 className="goldman">About US</h4>
        </div>
      </section>

      <section className="my-sm-5 my-3 our-storysection">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-3 mb-sm-0">
              <div className="top-headabout mb-sm-5 mb-3 ">
                <h2 className="goldman">Our Story</h2>
              </div>
              <div>
                {/* <img src={getData()} className="about-leftimg" alt="/" /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-center">
                <div className="col-md-11">
                  <div className="about-rightcontent">
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData(_aboutUsPage.ourStoryText),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-0 pb-sm-0 mb-4 position-relative">
        <div className="container">
          <div className="row mt-sm-4">
            <div className="col-md-12">
              <div className="top-headabout">
                <h2 className="goldman">
                  {getData(_aboutUsPage.aboutUsHeading)}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row mt-sm-4 mt-2">
            <div className="col-md-11 p-sm-0">
              <div className="portal-features">
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <ul>
                      <li>{getData(_aboutUsPage.descriptionOne)}</li>
                      <li>{getData(_aboutUsPage.descriptionTwo)}</li>
                      <li>{getData(_aboutUsPage.descriptionThree)}</li>
                      <li>{getData(_aboutUsPage.descriptionFour)}.</li>
                      <li> {getData(_aboutUsPage.descriptionFive)} </li>
                      <li>{getData(_aboutUsPage.descriptionSix)}</li>
                      <li>{getData(_aboutUsPage.descriptionSeven)}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="images/pseudo.png" className="pseudo-img" alt="/" />
      </section>

      <section className="why-section mt-sm-5 mt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="why-sec mt-3">
                <h2 class="goldman">Why Us</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-sm-5 pb-4 why-sectioncardbox">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-sm-0 mb-3">
              <div className="why-cardbox">
                <ul>
                  <li>{getData(_aboutUsPage.whyUsDescriptionOne)}</li>
                  <li>{getData(_aboutUsPage.whyUsDescriptionTwo)}</li>
                  <li>{getData(_aboutUsPage.whyUsDescriptionThree)}</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="why-cardbox">
                <ul>
                  <li>{getData(_aboutUsPage.whyUsDescriptionFour)}</li>
                  <li>{getData(_aboutUsPage.whyUsDescriptionFive)}</li>
                  <li>{getData(_aboutUsPage.whyUsDescriptionSix)}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-missionsection py-sm-5 py-4 mt-sm-4 mt-2">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 mb-sm-0 mb-4">
              <div className="mission-contentbox">
                <h2 className="goldman">OUR MISSION</h2>
              </div>

              <div className="row">
                <div className="col-md-10">
                  <div className="mission-contentbox">
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData(_aboutUsPage.ourMissionDescription),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <img
                  src={getData(_aboutUsPage.ourMissionImageUrl)}
                  className="mission-imgborder"
                  alt="/"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row mt-sm-4 mt-0">
            <div className="col-md-5 mb-sm-0 mb-2">
              <div className="mission-contentbox our-investors">
                <h2 className="goldman">OUR INVESTORS</h2>
              </div>
            </div>
            <div className="col-md-7">
              <div className="our-investors">
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(_aboutUsPage.ourInvestorsDescription),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-sm-5 mt-3 mb-sm-4 mb-3">
            <div className="col-md-3 col-6 mb-sm-0 mb-3">
              <div className="about-investors">
                <img
                  src={getData(_aboutUsPage.ourInvestorsImageOneUrl)}
                  alt="/"
                />
              </div>
            </div>
            <div className="col-md-3 col-6 mb-sm-0 mb-3">
              <div className="about-investors">
                <img
                  src={getData(_aboutUsPage.ourInvestorsImageTwoUrl)}
                  alt="/"
                />
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="about-investors">
                <img
                  src={getData(_aboutUsPage.ourInvestorsImageThreeUrl)}
                  alt="/"
                />
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="about-investors">
                <img
                  src={getData(_aboutUsPage.ourInvestorsImageFourUrl)}
                  alt="/"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </>

    // <>
    //   <Header />
    //   <section
    //     className="services-sectop"
    //     style={{
    //       backgroundImage: `url(${API_END_URLS.S3Bucket}${whoWeAre?.headerimg})`,
    //     }}
    //   >
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-md-12">
    //           <div className="services-tophead">
    //             <h1>Who We Are</h1>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   <section className="py-4 py-sm-5 ">
    //     <div className="container">
    //       <div className="row justify-content-center mb-2 mb-sm-5">
    //         <div className="col-md-11">
    //           <div className="row align-items-center">
    //             <div className="col-md-6 mb-3 mb-sm-0 pr-sm-0">
    //               <div className="new-imgbox">
    //                 <img src={S3Bucket + whoWeAre.section1img} alt="" />
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <div className="row justify-content-center">
    //                 <div className="col-md-9">
    //                   <div className="service-textbox">
    //                     <h1>{whoWeAre.sectionText1}</h1>
    //                     {/* <h1> <span className="service-textcolor">Spares.Com</span> is an
    //                                                 "<span className="service-marketplacecolor">Online Marketplace</span>" to
    //                                                 connect Buyers and Sellers associated with " <span className="service-marketplacecolor">Marine and Offshore</span>" Businesses.</h1> */}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="col-md-6 pt-0 pt-sm-5  order-2 order-sm-1">
    //               <div className="row">
    //                 <div className="col-md-10">
    //                   <div className="service-textbox">
    //                     <h1>{whoWeAre.sectionText2}</h1>
    //                     {/* <h1> This marketplace <br />  <span className="service-textcolor">Spares.Com</span> will bring Buyers and Sellers under one online platform where they can reach each other easily and quickly to market their products, spares and services. </h1> */}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="col-md-6 mb-3 mb-sm-0  pl-sm-0 pt-3 pt-sm-5 order-1 order-sm-2">
    //               <div className="new-imgbox">
    //                 <img src={S3Bucket + whoWeAre.section2img} alt="" />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>

    //   <section className="platform-sec py-4 py-sm-5">
    //     <div className="container">
    //       <div className="row justify-content-center ">
    //         <div className="col-md-11">
    //           <div className="row">
    //             <div className="col-md-5">
    //               <div className="platorm-box">
    //                 <h1>{whoWeAre.sectionText3}</h1>
    //                 {/* <h1>Our Vision <span> is to make this platform</span>  "Accessible Globally Across the Entire Marine and Offshore
    //                                         Community" and "Enable users to Reduce- Reuse - Recycle Products and Parts" <span>used for Marine and Offshore
    //                                             operations.</span></h1> */}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>

    //   <section className=" py-4 py-sm-5">
    //     <div className="container">
    //       <div className="row align-items-center justify-content-center ">
    //         <div className="col-md-9 text-center">
    //           <div className="service-textbox mt-2 mt-sm-5">
    //             <h1>{whoWeAre.sectionText4}</h1>
    //             {/* <h1><span className="service-marketplacecolor" >Our Misson</span> is to enable Buyers and Sellers
    //                                 ( an Individual or a Business Entity) to reach each other just by "a click or touch " .  This online
    //                                 platform can be accessed economically through  <span className="service-marketplacecolor" >Website and Mobile Apps.</span> </h1> */}
    //           </div>
    //         </div>
    //       </div>

    //       <div className="row justify-content-center my-2 my-sm-5">
    //         <div className="col-md-10 mt-2 mt-sm-5">
    //           <div className="row align-items-center ">
    //             <div className="col-md-6 ">
    //               <img src="images/mobile.png" alt="" />
    //             </div>

    //             <div className="col-md-6 mt-3 mt-sm-0">
    //               <div className="row justify-content-center">
    //                 <div className="col-md-11 col-lg-10">
    //                   <div className="our-mission">
    //                     <h1>Try our Sparess App !!</h1>
    //                     <p>
    //                       Get Connected to Buyers,Sellers & Service Providers
    //                       for your Marine & Offshore Needs, Anywhere and Anytime
    //                       with your Mobile
    //                     </p>
    //                   </div>

    //                   <div className="row mt-2 mt-lg-5 align-items-center">
    //                     <div className="col-lg-5 col-md-6 col-6">
    //                       <div
    //                         className="footbtn service-btn mb-3 mb-lg-4 mb-sm-2"
    //                         onClick={handleShows}
    //                       >
    //                         <div className="row align-items-center">
    //                           <div className="col-md-3 pr-0 col-3">
    //                             <img src="images/black-play.png" alt="" />
    //                           </div>
    //                           <div className="col-md-9 pl-2 col-9">
    //                             <h5>
    //                               Get it on <br /> <span>Google Play</span>
    //                             </h5>
    //                           </div>
    //                         </div>
    //                       </div>

    //                       <div
    //                         className="footbtn service-btn"
    //                         onClick={handleShows}
    //                       >
    //                         <div className="row align-items-center">
    //                           <div className="col-md-3 pr-0 col-3">
    //                             <img src="images/black-apple.png" alt="" />
    //                           </div>
    //                           <div className="col-md-9 pl-2 col-9">
    //                             <h5>
    //                               Get it on <br /> <span>Apple Store</span>
    //                             </h5>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className="col-lg-7 col-md-6 col-6">
    //                       <div className="qr-imgbox">
    //                         <img src="images/black-scan.png" alt="" />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>

    //   {/* <Footer /> */}

    //   <Modal
    //     show={show}
    //     onHide={handleClose}
    //     className="filter-popup modal-dialog-slideout home-pop"
    //   >
    //     <Modal.Header closeButton>
    //       <Modal.Title>
    //         {/* <div className="service-head">
    //                         <img src="images/popup-logo.svg" height="45px" alt="" />
    //                         <h1>your <span>address</span></h1>
    //                     </div> */}
    //         <img src="images/logo.png" alt="" />
    //       </Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <div className="row">
    //         <div className="col-md-12">
    //           <div className="home-popcontent">
    //             <ul>
    //               <li>
    //                 <a href="#">Contact Us</a>
    //               </li>
    //               <li>
    //                 <a href="#">Blogs</a>
    //               </li>
    //               <li>
    //                 <a href="#">Our Services</a>
    //               </li>
    //               <li>
    //                 <a href="#">About Sparess</a>
    //               </li>
    //               {/* <li><a href="#">Skin care Kits</a></li> */}
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </Modal.Body>
    //     <Modal.Footer>
    //       {/* <button type="button" className="btn btn-outline-primary post-btn mr-2">BACK</button>
    //                 <button type="button" className="btn btn-login">SELECT DATE & TIME</button> */}
    //       {/* <Button variant="secondary" onClick={handleClose}>Close</Button> */}
    //       {/* <Button variant="primary" onClick={handleClose}>Save Changes</Button> */}
    //     </Modal.Footer>
    //   </Modal>

    //   <Modal show={shows} onHide={handleCloses} className="qr-popup">
    //     <Modal.Header closeButton>
    //       <Modal.Title></Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <div className="row text-center">
    //         <div className="col-md-12">
    //           <div className="qr-popupimg">
    //             <img src="images/pop-qr.png" alt="" />
    //           </div>
    //         </div>
    //       </div>
    //     </Modal.Body>
    //     {/* <Modal.Footer>
    //                 <button type="button" className="btn btn-outline-primary post-btn mr-2">BACK</button>
    //                 <button type="button" className="btn btn-login">PROCEED TO PAYMENT</button>

    //             </Modal.Footer> */}
    //   </Modal>
    // </>
  );
}

export default AboutsUs;
