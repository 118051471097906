import React from "react";
import { useNavigate } from "react-router-dom";

export default function PreviewAdverstisement({ formikProp }) {
  const navigate = useNavigate();

  const { values } = formikProp;

  return (
    <React.Fragment>
      <div class="Advertisement-box mt-2">
        <div className="row justify-content-center">
          <div className="col-md-7">
            <div class="row">
              <div class="col-md-8 mb-3 mb-sm-0 pl-3 pl-sm-0">
                <h1>Type of Advertisement</h1>
                <p>{values?.advertisement_type}</p>
                <div class="row mt-3 mt-sm-5">
                  <div class="col-md-6 col-4">
                    {values?.advertisement_type === "Banner" ? (
                      <>
                        <div class="col-md-12 mb-3">
                          <h1>Banner</h1>
                        </div>

                        <div class="secound-buybox">
                          <img
                            src={values?.media_preview}
                            class="first-buyimg"
                            alt=""
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="col-md-12 mb-3">
                          <h1>Video</h1>
                        </div>
                        <div class="secound-buybox">
                          <iframe
                            src={values?.media_preview}
                            class="first-buyimg"
                            title="description"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <h1>Duration</h1>
                <p>{values?.video_duration} Sec</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 justify-content-center">
        <div className="col-md-3">
        <div
          type="button"
          data-target="#preview"
          data-toggle="tab"
          className="btn back-btn btn-block mr-2"
          onClick={() => navigate(-1)}
        >
          Back
        </div>
        </div>
        <div className="col-md-3">
          <button
            type="submit"
            className="btn pre-btn btn-block"
            onClick={() => navigate()}
          >
            Payment
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
