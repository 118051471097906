export function fieldValidate({ field, value }) {
    if (field === "name") {
        if (value === "") {
            return { isValid: false, msg: "Please enter username." };
        }
        else {
            return { isValid: true }
        }
    }
    else if (field === "password" || field === "newPassword") {
        if (value === "") {
            return { isValid: false, msg: "Please enter password." };
        }
        else if (value.trim().length < 8) {
            return { isValid: false, msg: "Password have atleast 8 character." };
        }
        else {
            return { isValid: true }
        }
    }
    else if (field === "confirmPassword") {
        if (value === "") {
            return { isValid: false, msg: "Please enter confirm password." };
        }
        else if (value.trim().length < 8) {
            return { isValid: false, msg: "Confirm password have atleast 8 character." };
        }
        else {
            return { isValid: true }
        }
    }
    else if (field === "otp") {
        if (value === "") {
            return { isValid: false, msg: "Please enter OTP." };
        }
        else if (value.length !== 6) {
            return { isValid: false, msg: "Please enter 6 digit Otp" };
        }
        const reg = new RegExp('^[0-9]+$');
        const res = reg.test(value);
        if (!res) {
            return { isValid: false, msg: "OTP includes only digits." };
        }
        else {
            return { isValid: true };
        }
    }
    else if (field === "mobileNo") {
        if (value === "") {
            return { isValid: false, msg: "Please enter mobileNo." };
        }
        else if (value.length !== 10) {
            return { isValid: false, msg: "Please enter 10 digit mobile number." };
        }
        const reg = new RegExp('^[0-9]+$');
        const res = reg.test(value);
        if (!res) {
            return { isValid: false, msg: "Mobile number includes digits" };
        }
        else {
            return { isValid: true };
        }
    }
    else if (field === "email") {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const test = reg.test(value.toLowerCase());
        if (!test) {
            return { isValid: false, msg: 'Please enter a valid email.' };
        }
        else {
            return { isValid: true };
        }
    }
    else if (field === "age") {
        if (value === "") {
            return { isValid: false, msg: "Age is required" };
        }
        else if (isNaN(value)) {
            return { isValid: false, msg: "Please enter valid age number" };
        }
        else if (value < 10 || value > 80) {
            return { isValid: false, msg: "Age should be inbetween 10-80" };
        }
        else {
            return { isValid: true };
        }
    }
    else {
        return { isValid: false, msg: 'Invalid field' };
    }
}

export function objectValidate(data, options) {
    /* options should be key list that should be ignore */
    for (const [key, value] of Object.entries(data)) {
        if (options && options.includes(key)) { }
        else if (!value) {
            return { isValid: false, msg: `Please enter ${key}`, key: key };
        } else if (key === "email") {
            let res = fieldValidate({ field: key, value })
            if (!res.isValid) {
                return res
            }
        }
        else if (key === "password" || key === "confirmPassword" || key === "newPassword") {
            let res = fieldValidate({ field: key, value })
            if (!res.isValid) {
                return res
            }
        }
        else if (key === "otp") {
            let res = fieldValidate({ field: key, value })
            if (!res.isValid) {
                return res
            }
        }

    }
    return { isValid: true };
}

export function changePasswordValidate({ oldPassword, newPassword, confirmPassword }) {
    if (!oldPassword || !newPassword || !confirmPassword) {
        return { isValid: false, msg: `All password field are required` };
    }
    if (newPassword !== confirmPassword) {
        return { isValid: false, msg: `Password mismatch` };
    }
    return { isValid: true };
}
