import React, { useEffect, useState } from "react";
// import React from 'react'
import SparessSidemenu from "../include/sparess-sidemanu";
// import { Link, useNavigate, useParams } from 'react-router-dom'
import Sparessheader from "../include/sparess-header";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../frontend/toast";
import moment from "moment/moment";

// import moment from 'moment'

function Excisting() {
  const navigate = useNavigate();

  const [allProducts, setAllProducts] = useState([]);

  const getAllProducts = async (req, res) => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.allPromotions,
        method: "get",
        token: true,
      });
      if (success) {
        setAllProducts(data?.allPromotions);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: excisting.js:32 ~ getAllProducts ~ err:", err);
    }
  };

  const handleRedirect = (item) => {
    navigate(`/range-products/${item?.product_id}`);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      <div className="container-fluid" id="seller-panel">
        <div className="row">
          <div className="col-md-3 pr-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox ">
                <Sparessheader></Sparessheader>
              </div>

              <div class="pageinnner-padding page-inner-scroll">
                <div className="d-flex justify-content-between align-items-center">
                  <div class="head-text">
                    <h1 className="border-0 p-0">All Promotions</h1>
                  </div>
                  <div className="back-arrow">
                    <Link to="/seller/promotion">
                      <button className="back-arrow-btn btn">
                        <img src="/images/left-arrow-back.svg" alt="/" />
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="border-bottom my-3"></div>

                <div className="promotion-card-area">
                  <div className="row">
                    {allProducts.map((item, index) => (
                      <div
                        className="col-md-6 pl-0 mt-3"
                        onClick={() => handleRedirect(item)}
                        key={index}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className={`promotion-card promotion-card-${
                            (index % 3) + 1
                          }`}
                        >
                          <div className="promt-headtext">
                            <div className="row">
                              <div className="col-md-6">
                                {" "}
                                <h1>{item?.CategoryProduct?.product_name}</h1>
                              </div>

                              <div className="col-md-6">
                                {item?.Product?.product_type === 0 ? (
                                  <p>
                                    Exp.{" "}
                                    <span>
                                      {moment(
                                        item?.Product?.expiry_date
                                      ).format("DD/MM/yyy")}
                                    </span>
                                    {moment(item?.Product?.expiry_date).format(
                                      "h:mm"
                                    )}
                                  </p>
                                ) : (
                                  <p>
                                    Exp.{" "}
                                    <span>
                                      {moment(item?.Product?.to_date).format(
                                        "DD/MM/yyy"
                                      )}
                                    </span>
                                    {moment(item?.Product?.to_date).format(
                                      "h:mm"
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="promt-content-text mt-4">
                            <p> {item?.product_promotion_description} </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Excisting;
